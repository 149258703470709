<ng-container *ngIf="questions let questions">
  <div cdkDropList (cdkDropListDropped)="reorderQuestion($event)">
    <div [cdkDragData]="questions" cdkDrag class="row" *ngFor="let question of questions; let index = index">
      <app-question-edit [questionnaireId]="questionnaire!.id!" [questions]="questions" [question]="question" [questionIndex]="index" [validations]="validations" [operatorTypes]="operatorTypes" [questionTypes]="questionTypes" (delete)="openDeleteDialog($event)"></app-question-edit>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="container">
    <button mat-raised-button class="d-inline primary" (click)="addMainQuestion()">{{ "FORMS.QUESTIONNAIRE.NEW_MAIN_QUESTION" | translate }}</button>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div id="questionnaire-delete-buttons" class="col-md">
        <h3 class="title">{{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.TITLE" | translate }}</h3>
        <p>{{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.DESCRIPTION" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialog.close()">{{ "FORMS.CANCEL" | translate }}</button>
        <button mat-raised-button color="warn" (click)="deleteQuestion(data); dialog.close()">
          {{ "FORMS.QUESTIONNAIRE.DELETE_QUESTION.SINGLE_QUESTION" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-template>

import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { v4 as generateUUID } from "uuid";
import { OperatorType } from "../../../../classes/flow/Questionnaire/OperatorType";
import { Question } from "../../../../classes/flow/Questionnaire/Question";
import { QuestionType } from "../../../../classes/flow/Questionnaire/QuestionType";
import { Validation } from "../../../../classes/flow/Questionnaire/Validation";
import { Entity } from "../../../../classes/flow/base/Entity";
import { compareEntities } from "../../../../helpers/compareObjects";
import { QuestionService } from "../../../../services/question.service";
import { Option } from "../../../../classes/flow/Questionnaire/Option";
import { filter, debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import { Subscription } from "rxjs";
import { ApplicationService } from "../../../../services/application.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-question-edit",
  templateUrl: "./question-edit.component.html",
  styleUrls: ["./question-edit.component.less"],
})
export class QuestionEditComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public questions!: Question[]; // all questions

  @Input()
  public operatorTypes: OperatorType[]; // operator type options for select

  @Input()
  public validations: Validation[]; // validation type options for select

  @Input()
  public questionTypes: QuestionType[]; // type options for select

  @Input()
  public question!: Question; // question object

  @Input()
  public questionIndex!: number; // index of this question

  @Input()
  public indexPrefix: string | null; // prefix of index for exmpl 1.2

  @Input()
  public questionnaireId!: number;

  @Output()
  public delete: EventEmitter<Question>;

  public form: FormGroup<QuestionFormGroup>; // form of question
  public optionControls: FormArray<FormControl<string | null>>;

  public formSubscription?: Subscription;

  public defaultValidationValue: Validation | null;
  public defaultOperatorTypeValue: OperatorType | null;
  public defaultQuestionTypeValue: QuestionType | null;

  constructor(
    private questionService: QuestionService,
    private application: ApplicationService,
    private snackService: SnackbarService,
    private translateService: TranslateService
  ) {
    this.operatorTypes = [];
    this.validations = [];
    this.questionTypes = [];
    this.indexPrefix = null;
    this.optionControls = new FormArray<FormControl<string | null>>([]);

    // Initalize formgroup
    this.form = new FormGroup({
      name: new FormControl<string | null>(null, [Validators.required, Validators.pattern(/^[^:]*$/)]),
      description: new FormControl<string | null>(null, [Validators.pattern(/^[^:]*$/)]),
      questionType: new FormControl<QuestionType | null>(null, [Validators.required]),
      placeholder: new FormControl<string | null>(null, [Validators.required, Validators.pattern(/^[^:]*$/)]),
      required: new FormControl<boolean | null>(null, [Validators.required]),
      validation: new FormControl<Validation | null>(null, [Validators.required]),
      operatorType: new FormControl<OperatorType | null>(null),
      compareAnswer: new FormControl<string | null>(null),
      compareOptionId: new FormControl<number | null>(null),
      compareOptionReference: new FormControl<string | null>(null),
      unitPrice: new FormControl<number | null>(null, [Validators.required, Validators.min(0), Validators.max(1000)]),
    });

    this.delete = new EventEmitter();

    this.defaultValidationValue = null;
    this.defaultOperatorTypeValue = null;
    this.defaultQuestionTypeValue = null;
  }

  public async ngOnInit() {
    this.defaultValidationValue = this.validations.filter((v) => v.name === "none")[0] ?? null;
    this.defaultOperatorTypeValue = this.operatorTypes.filter((o) => o.name === "Equals")[0] ?? null;
    this.defaultQuestionTypeValue = this.questionTypes.filter((q) => q.prefix === "txt")[0] ?? null;

    this.setFormValues();
    this.setOptionControls();
    this.form.valueChanges
      .pipe(
        tap(async () => {
          await this.executeSideEffects();
        }),
        filter(() => this.form.valid),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(async () => {
        await this.saveQuestion();
      });

    await this.executeSideEffects();
  }

  public ngOnChanges() {
    // Update changed indices
    this.checkIndexChanged();
  }

  public ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
  }

  // Set form values according to question object
  public async setFormValues() {
    this.form.controls.name.patchValue(this.question.name, { emitEvent: false });
    this.form.controls.description.patchValue(this.question.description, { emitEvent: false });
    this.form.controls.questionType.patchValue(this.question.questionType, { emitEvent: false });
    this.form.controls.placeholder.patchValue(this.question.placeholder, { emitEvent: false });
    this.form.controls.required.patchValue(this.question.required, { emitEvent: false });
    this.form.controls.validation.patchValue(this.question.validation ?? this.defaultValidationValue, { emitEvent: false });
    this.form.controls.unitPrice.patchValue(this.question.extraProperties.unitPrice ?? null, { emitEvent: false });
    this.form.controls.operatorType.patchValue(this.question.logic?.operatorType ?? this.defaultOperatorTypeValue, { emitEvent: false });
    this.form.controls.compareAnswer.patchValue(this.question.logic?.compareAnswer ?? null, { emitEvent: false });
    this.form.controls.compareOptionId.patchValue(this.question.logic?.compareOptionId ?? null, { emitEvent: false });
    this.form.controls.compareOptionReference.patchValue(this.question.logic?.compareOptionReference ?? null, { emitEvent: false });

    // disale child fields if question has logic
    if (!this.question.logic) {
      this.form.controls.operatorType.disable({ emitEvent: false });
      this.form.controls.compareAnswer.disable({ emitEvent: false });
      this.form.controls.compareOptionId.disable({ emitEvent: false });
      this.form.controls.compareOptionReference.disable({ emitEvent: false });
    }
  }

  public setOptionControls() {
    this.optionControls = new FormArray<FormControl<string | null>>([]);
    if (this.question.options?.length) {
      for (const option of this.question.options) {
        this.optionControls.push(new FormControl<string | null>(option.value, [Validators.required]));
      }
    } else if (this.optionsCanBeAdded) {
      for (let i = 0; i < 2; i++) {
        this.addSelectOption();
      }
    }
    this.optionControls.markAsPristine();
  }

  // Compare entity objects
  public compareEntities(firstEntity: Entity | null, secondEntity: Entity | null) {
    if (!firstEntity || !secondEntity) return false;
    if (!firstEntity.id || !secondEntity.id) return false;
    return compareEntities(firstEntity, secondEntity);
  }

  // Compare Options Id's from select box
  public compareSelectOptions(firstSelectOptionId: string | null, secondSelectOptionId: string | null): boolean {
    if (!firstSelectOptionId || !secondSelectOptionId) return false;
    return firstSelectOptionId == secondSelectOptionId;
  }

  // Add select option to array
  public addSelectOption() {
    if (!this.question.options) {
      this.question.options = [];
    }

    this.question.options.push({
      value: "",
      questionId: this.question.id,
      questionReference: generateUUID(),
    });

    this.optionControls.push(new FormControl<string | null>("", [Validators.required]));
  }

  // Add follow up question object to array
  public addFollowUpQuestion() {
    this.question.followUpQuestions.push({
      active: true,
      description: "",
      extraProperties: {},
      followUpQuestions: [],
      name: "",
      placeholder: "",
      required: true,
      questionType: this.defaultQuestionTypeValue!,
      validation: this.defaultValidationValue ?? undefined,
      logic: {
        parentQuestionId: this.question.id!,
      },
    });
  }

  // Delete select option
  public async deleteSelectOption(index: number) {
    // Remove from formArray
    this.optionControls.removeAt(index);

    const id = this.question.options?.[index].id;
    if (id) {
      await this.questionService.deleteQuestionOption(id);
    }

    // Remove from question object
    this.question.options?.splice(index, 1);
  }

  // Save select option
  public async saveSelectOption(index: number) {
    this.application.setLoading(true);
    try {
      // Get option reference from question object
      const questionOption = this.question.options![index];
      // Get form control for that option
      const formOption = this.optionControls.at(index);

      let updatedOption: Option | undefined;
      if (questionOption.id) {
        // update
        updatedOption = await this.questionService.updateQuestionOption({
          id: questionOption.id,
          questionId: this.question.id!,
          value: formOption.value!,
          changes: questionOption.changes,
        });
      } else {
        // insert
        updatedOption = await this.questionService.addQuestionOption({
          questionId: this.question.id!,
          questionReference: questionOption.questionReference!,
          value: formOption.value!,
        });
      }
      // Update question object
      questionOption.changes = updatedOption.changes;
      questionOption.id = updatedOption.id;
      questionOption.questionId = updatedOption.questionId;
      questionOption.value = updatedOption.value;
      questionOption.questionReference = updatedOption.questionReference;
      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.SAVED"), "", 2000);
    } catch (error) {
      this.snackService.error();
    }
    this.application.setLoading(false);
  }

  public async saveQuestion() {
    this.application.setLoading(true);
    try {
      const formValue = this.form.value;
      const newQuestion: Question = {
        id: this.question.id,
        active: this.question.active,
        description: formValue.description ?? this.question.description,
        followUpQuestions: [],
        name: formValue.name ?? this.question.name,
        placeholder: formValue.placeholder ?? this.question.placeholder,
        questionType: formValue.questionType ?? this.question.questionType,
        required: formValue.required ?? this.question.required,
        validation: formValue.validation ?? undefined,
        index: this.newIndexPrefix,
        extraProperties: {
          unitPrice: formValue.unitPrice ?? this.question.extraProperties.unitPrice ?? undefined,
        },
        changes: this.question.changes,
      };

      if (this.question.logic?.parentQuestionId) {
        newQuestion.logic = {
          parentQuestionId: this.question.logic?.parentQuestionId ?? 0,
          compareAnswer: formValue.compareAnswer ?? this.question.logic?.compareAnswer,
          compareOptionId: formValue.compareOptionId ?? this.question.logic?.compareOptionId,
          compareOptionReference: formValue.compareOptionReference ?? this.question.logic?.compareOptionReference,
          operatorType: formValue.operatorType ?? this.question.logic?.operatorType,
        };
      }

      let result: Entity | undefined;
      if (this.question.id) {
        // update
        result = await this.questionService.updateQuestion(newQuestion);
      } else {
        // insert
        result = await this.questionService.addQuestion(newQuestion, this.questionnaireId);
      }
      if (!result.id) throw new Error("Mutation Failed");

      this.question.id = result.id;
      this.question.changes = result.changes;
      this.question.description = newQuestion.description;
      this.question.name = newQuestion.name;
      this.question.placeholder = newQuestion.placeholder;
      this.question.questionType = newQuestion.questionType;
      this.question.required = newQuestion.required;
      this.question.validation = newQuestion.validation;
      this.question.index = newQuestion.index;
      this.question.logic = newQuestion.logic;
      this.question.extraProperties = newQuestion.extraProperties;

      this.form.markAsPristine();

      this.snackService.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.SAVED"), "", 2000);
    } catch (error) {
      this.snackService.error();
    }
    this.application.setLoading(false);
  }

  // Reorder questions
  public reorderQuestion(event: CdkDragDrop<Question[]>) {
    moveItemInArray(event.item.data, event.previousIndex, event.currentIndex);

    this.checkIndexChanged();
  }

  // Evaluate if index of question changed, if so update it
  public checkIndexChanged() {
    if (this.question.index !== this.newIndexPrefix) {
      this.saveQuestion();
    }
  }

  // Get new index for this question (combine current prefix and current index)
  public get newIndexPrefix() {
    return this.indexPrefix ? `${this.indexPrefix}.${this.questionIndex + 1}` : `${this.questionIndex + 1}`;
  }

  // Wether follow up question add button should be shown
  public get followUpQuestionCanBeAdded() {
    return this.form.controls.questionType.value?.prefix !== "upp";
  }

  // Wether follow up question add button should be shown
  public get optionsCanBeAdded() {
    return this.form.controls.questionType.value?.prefix === "sel" || this.form.controls.questionType.value?.prefix === "sem";
  }

  public get showUnitPrice() {
    return this.form.controls.questionType.value?.prefix === "upp";
  }

  // returns parent question (null if this question is main question)
  public get parentQuestion(): Question | null {
    if (!this.question.logic?.parentQuestionId) return null;

    return this.questions.find((q) => q.id === this.question.logic?.parentQuestionId) ?? null;
  }

  public get parentQuestionOptions(): Option[] {
    return this.parentQuestion?.options?.filter((v) => !!v.id) ?? [];
  }

  // returns true if parent field is of type date
  public get parentIsDate(): boolean {
    return this.parentQuestion?.questionType.prefix === "dat";
  }

  // Function to execute every time form value changes
  public async executeSideEffects() {
    // Disable/enable unit price
    if (this.showUnitPrice) {
      this.form.controls.unitPrice.enable({ emitEvent: false });
    } else {
      this.form.controls.unitPrice.disable({ emitEvent: false });
    }

    if (this.form.controls.questionType.value?.prefix === "sel" || this.form.controls.questionType.value?.prefix === "sem") {
      // disable validation on select types
      this.form.controls.validation.disable({ emitEvent: false });
      this.form.controls.validation.patchValue(null, { emitEvent: false });
      this.form.controls.validation.markAsPristine();
    } else {
      // enable validation if not a select
      if (this.form.controls.validation.disabled) {
        this.form.controls.validation.enable({ emitEvent: false });
        this.form.controls.validation.markAsTouched();
        this.form.controls.validation.patchValue(this.defaultValidationValue, { emitEvent: false });
      }

      // delete options
      while (this.optionControls.length) {
        await this.deleteSelectOption(0);
      }
    }

    this.setOptionControls();

    // // Set validators form question type
    // const questionTypeControl = this.form.controls.questionType;
    // if (questionTypeControl.value?.prefix === "dat") {
    //   questionTypeControl.setValidators([Validators.required]);
    // } else {
    //   questionTypeControl.setValidators([Validators.required, Validators.pattern(/^(===$|!=$)/)]);
    // }
    this.form.updateValueAndValidity({ emitEvent: false });
  }

  public onDelete(question: Question) {
    this.delete.emit(question);
  }
}

type QuestionFormGroup = {
  name: FormControl<string | null>;
  description: FormControl<string | null>;
  questionType: FormControl<QuestionType | null>;
  placeholder: FormControl<string | null>;
  required: FormControl<boolean | null>;
  validation: FormControl<Validation | null>;
  operatorType: FormControl<OperatorType | null>;
  compareAnswer: FormControl<string | null>;
  compareOptionId: FormControl<number | null>;
  compareOptionReference: FormControl<string | null>;
  unitPrice: FormControl<number | null>;
};

import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { RequestStates } from "../../../classes/flow/request/RequestStates";
import { RoleName } from "../../../classes/flow/session/Session";
import { ApplicationService } from "../../../services/application.service";
import { FilterGroup } from "../../badge-filters/badge-filters";
import { Header } from "../../table/Header";

interface SpecialtyValue {
  show: boolean;
  allConsults: EnergyConsult[];
  ownedConsults: EnergyConsult[];
  filteredByStringConsults: EnergyConsult[];
}

interface StateValue {
  show: boolean;
  value: Record<string, SpecialtyValue>;
}

@Component({
  selector: "app-consult-list-view",
  templateUrl: "./consult-list-view.component.html",
  styleUrls: ["./consult-list-view.component.less"],
})
export class ConsultListViewComponent implements OnInit, OnDestroy {
  @ViewChild("requestTable") requestTable: any;

  @Input("consults")
  set valueSetter(consults: EnergyConsult[]) {
    this.setValue(consults);
  }

  @Input("showOtherCoaches") showOtherCoaches!: FormControl<boolean | null>;

  @Input("filters")
  set filtersSetter(filters: FilterGroup<EnergyConsult>[]) {
    this.setShown(filters);
  }

  @Input("filterString")
  set filterStringUpdater(value: string | null) {
    this.filterString = value ?? "";
    this.setFilteredByStringConsults();
  }

  private showOtherCoachesSubscription?: Subscription;

  public energyConsultListHeaders: Header[] = [];

  public role: RoleName | null = null;

  value: {
    [key: string]: StateValue;
  } = {};

  public filterString = "";

  constructor(private readonly application: ApplicationService, private readonly translate: TranslateService) {
    this.setHeaders();
  }
  ngOnInit(): void {
    this.role = this.application.session.activeRole.name;
    this.showOtherCoachesSubscription = this.showOtherCoaches.valueChanges.subscribe(() => {
      this.setFilteredByStringConsults();
    });
  }
  ngOnDestroy(): void {
    if (this.showOtherCoachesSubscription) this.showOtherCoachesSubscription.unsubscribe();
  }

  public setValue(consults: EnergyConsult[]) {
    const coachId = this.application.session.user?.id;
    let states = [...new Set(consults.map((request) => request.state.name))];
    const statesOrder = Object.values(RequestStates);
    states = states.sort((a, b) => statesOrder.indexOf(a) - statesOrder.indexOf(b));
    const specialties = [...new Map(consults.map((request) => [request.specialty.name, request.specialty])).values()];
    for (const state of states) {
      let stateValue = {};
      for (const specialty of specialties) {
        stateValue = {
          [specialty.name]: {
            show: true,
            allConsults: consults.filter((consult) => consult.state.name === state && consult.specialty.name === specialty.name),
            ownedConsults: consults.filter(
              (consult) =>
                consult.state.name === state && consult.specialty.name === specialty.name && (consult.coach?.id === coachId || consult.state.name === RequestStates.NEW)
            ),
          },
          ...stateValue,
        };
      }
      this.value[state] = {
        show: true,
        value: stateValue,
      };
    }
  }

  public getStateKeys(): string[] {
    return Object.keys(this.value);
  }

  public getPerState(state: RequestStates | string):
    | {
        show: boolean;
        value: Record<
          string,
          {
            show: boolean;
            allConsults: EnergyConsult[];
            ownedConsults: EnergyConsult[];
          }
        >;
      }
    | undefined {
    return this.value[state as RequestStates];
  }

  public getSpecialtyKeys(state: RequestStates | string): string[] {
    return Object.keys(this.value[state as RequestStates]?.value ?? {});
  }

  public getPerSpecialtyInState(state: RequestStates | string, specialty: string): SpecialtyValue {
    return (this.value[state as RequestStates]?.value ?? {})[specialty];
  }

  public getConsults(state: RequestStates | string, specialty: string, filtered = true): EnergyConsult[] {
    if (this.filterString && filtered) return (this.value[state as RequestStates]?.value ?? {})[specialty].filteredByStringConsults ?? [];
    return this.showOtherCoaches?.value || this.application.session.activeRole.name === "coordinator"
      ? (this.value[state as RequestStates]?.value ?? {})[specialty].allConsults ?? []
      : (this.value[state as RequestStates]?.value ?? {})[specialty].ownedConsults ?? [];
  }

  public isStateEmpty(state: RequestStates | string): boolean {
    for (const specialty of this.getSpecialtyKeys(state)) {
      if (this.getConsults(state, specialty).length > 0 && this.getPerSpecialtyInState(state, specialty).show) {
        return false;
      }
    }
    return true;
  }

  public setShown(filters: FilterGroup<EnergyConsult>[]) {
    for (const filter of filters) {
      switch (filter.name) {
        case "specialty":
          for (const state of this.getStateKeys()) {
            for (const specialty of this.getSpecialtyKeys(state)) {
              this.getPerSpecialtyInState(state, specialty).show = !!filter.activeFilters?.find((filter) => filter.title.split(".").pop() === specialty);
            }
          }
          break;
        case "state":
          for (const state of this.getStateKeys()) {
            this.getPerState(state)!.show = !!filter.activeFilters?.find((filter) => filter.title.split(".").pop() === state);
          }
          break;
        default:
          break;
      }
    }
  }

  public sort(sortMethod: "date" | "lastStateChangeDate", sortAscending: boolean) {
    switch (sortMethod) {
      case "lastStateChangeDate":
        if (sortAscending) {
          for (const state of this.getStateKeys()) {
            for (const specialty of this.getSpecialtyKeys(state)) {
              this.getPerSpecialtyInState(state, specialty).allConsults = this.getPerSpecialtyInState(state, specialty).allConsults.sort(
                (a, b) => a.lastStateChangeDate!.getTime() - b.lastStateChangeDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).ownedConsults = this.getPerSpecialtyInState(state, specialty).ownedConsults.sort(
                (a, b) => a.lastStateChangeDate!.getTime() - b.lastStateChangeDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).filteredByStringConsults = this.getPerSpecialtyInState(state, specialty).filteredByStringConsults.sort(
                (a, b) => a.lastStateChangeDate!.getTime() - b.lastStateChangeDate!.getTime()
              );
            }
          }
        } else {
          for (const state of this.getStateKeys()) {
            for (const specialty of this.getSpecialtyKeys(state)) {
              this.getPerSpecialtyInState(state, specialty).allConsults = this.getPerSpecialtyInState(state, specialty).allConsults.sort(
                (a, b) => b.lastStateChangeDate!.getTime() - a.lastStateChangeDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).ownedConsults = this.getPerSpecialtyInState(state, specialty).ownedConsults.sort(
                (a, b) => b.lastStateChangeDate!.getTime() - a.lastStateChangeDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).filteredByStringConsults = this.getPerSpecialtyInState(state, specialty).filteredByStringConsults.sort(
                (a, b) => b.lastStateChangeDate!.getTime() - a.lastStateChangeDate!.getTime()
              );
            }
          }
        }
        break;
      case "date":
        if (sortAscending) {
          for (const state of this.getStateKeys()) {
            for (const specialty of this.getSpecialtyKeys(state)) {
              this.getPerSpecialtyInState(state, specialty).allConsults = this.getPerSpecialtyInState(state, specialty).allConsults.sort(
                (a, b) => a.requestDate!.getTime() - b.requestDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).ownedConsults = this.getPerSpecialtyInState(state, specialty).ownedConsults.sort(
                (a, b) => a.requestDate!.getTime() - b.requestDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).filteredByStringConsults = this.getPerSpecialtyInState(state, specialty).filteredByStringConsults.sort(
                (a, b) => a.requestDate!.getTime() - b.requestDate!.getTime()
              );
            }
          }
        } else {
          for (const state of this.getStateKeys()) {
            for (const specialty of this.getSpecialtyKeys(state)) {
              this.getPerSpecialtyInState(state, specialty).allConsults = this.getPerSpecialtyInState(state, specialty).allConsults.sort(
                (a, b) => b.requestDate!.getTime() - a.requestDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).ownedConsults = this.getPerSpecialtyInState(state, specialty).ownedConsults.sort(
                (a, b) => b.requestDate!.getTime() - a.requestDate!.getTime()
              );
              this.getPerSpecialtyInState(state, specialty).filteredByStringConsults = this.getPerSpecialtyInState(state, specialty).filteredByStringConsults.sort(
                (a, b) => b.requestDate!.getTime() - a.requestDate!.getTime()
              );
            }
          }
        }
        break;

      default:
        break;
    }

    if (this.requestTable) {
      this.requestTable.reload();
    }
  }

  private setHeaders() {
    this.energyConsultListHeaders.push({
      visualName: "COACH",
      resolver: this.displayCoach,
      sorting: {
        sort: true,
        customSortFunction(element: EnergyConsult) {
          return element.coach?.fullName.toLowerCase() ?? "zzz";
        },
      },
    });
    this.energyConsultListHeaders.push({
      visualName: "DATE",
      resolver: this.displayDate,
      sorting: {
        sort: true,
        customSortFunction(element) {
          return element.requestDate.getTime();
        },
      },
    });
    this.energyConsultListHeaders.push({
      visualName: "APPOINTMENT",
      resolver: this.displayAppointment,
      sorting: {
        sort: true,
        customSortFunction(element: EnergyConsult) {
          return element.appointmentDate?.getTime() ?? "zzz";
        },
      },
    });
    this.energyConsultListHeaders.push({
      visualName: "LOCATION",
      resolver: this.displayLocation,
      sorting: {
        sort: true,
      },
    });
    this.energyConsultListHeaders.push({
      visualName: "POSTALCODE",
      resolver: this.displayPostalCode,
      sorting: {
        sort: true,
      },
    });

    this.energyConsultListHeaders.push({
      visualName: "OPEN",
      link: { baseUrl: this.application.session.activeRole.name === "coach" ? "/content/coach/request/" : "/content/coordinator/request/", params: ["id"] },
    });
  }

  public displayState: (request: EnergyConsult) => string = (request) => {
    return this.translate.instant("COMPONENTS.TILE.STATUSES." + request.state.name);
  };

  public displaySpecialty: (request: EnergyConsult) => string = (request) => {
    return request.specialty.name;
  };

  public displayCoach: (request: EnergyConsult) => string = (request) => {
    return request.coach ? request.coach.firstName + " " + request.coach.lastName : this.translate.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  public displayDate: (request: EnergyConsult) => string = (request) => {
    return request.requestDate ? request.requestDate.toLocaleDateString() : this.translate.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  public displayAppointment: (request: EnergyConsult) => string = (request) => {
    return request.appointmentDate
      ? request.appointmentDate.toLocaleDateString() + " " + request.appointmentDate.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
      : this.translate.instant("COMPONENTS.TILE.ABSENT_DATA");
  };

  // public displayLocation: (request: EnergyConsult) => string = (request) => {
  //   return request.requestDate ? request.requestDate.toLocaleDateString() : this.translate.instant("COMPONENTS.TILE.ABSENT_DATA");
  // };

  public displayHouseNumber: (request: EnergyConsult) => string = (request) => {
    return request.houseNumberSuffix ? String(request.houseNumber + request.houseNumberSuffix.toUpperCase()) : String(request.houseNumber);
  };
  public displayLocation: (request: EnergyConsult) => string = (request) => {
    return request.getLocation(this.application);
  };

  public displayPostalCode: (request: EnergyConsult) => string = (request) => {
    return request.postalCode.substring(0, 4) + " " + request.postalCode.substring(request.postalCode.length - 2, request.postalCode.length).toUpperCase();
  };

  public setFilteredByStringConsults() {
    const field = this.filterString;
    for (const stateKey of this.getStateKeys()) {
      for (const specialtyKey of this.getSpecialtyKeys(stateKey)) {
        this.value[stateKey].value[specialtyKey].filteredByStringConsults = this.getConsults(stateKey, specialtyKey, false)
          .map((consult) => consult)
          .filter((econsult) => {
            if (
              (econsult.coach?.fullName.toLowerCase().includes(field.toLowerCase()) && econsult.coach?.fullName.length >= field.length) ||
              (econsult.coach?.email.toLowerCase().includes(field.toLowerCase()) && econsult.coach?.email.length >= field.length) ||
              (econsult.resident?.fullName.toLowerCase().includes(field.toLowerCase()) && econsult.resident?.fullName.length >= field.length) ||
              (econsult.resident?.email.toLowerCase().includes(field.toLowerCase()) && econsult.resident?.email.length >= field.length) ||
              (econsult.postalCode.toLowerCase().includes(field.toLowerCase()) && econsult.postalCode.length >= field.length) ||
              ((String(econsult.houseNumber).toLowerCase() + econsult.houseNumberSuffix?.toLowerCase()).includes(field.toLowerCase()) &&
                econsult.message.length >= field.length) ||
              (econsult.houseNumberSuffix?.toLowerCase().includes(field.toLowerCase()) && econsult.houseNumberSuffix.length >= field.length) ||
              (econsult.extraProperties?.streetname?.toLowerCase().includes(field.toLowerCase()) && econsult.extraProperties.streetname.length >= field.length) ||
              (econsult.extraProperties?.addressdetails?.town?.toLowerCase().includes(field.toLowerCase()) &&
                econsult.extraProperties.addressdetails.town.length >= field.length) ||
              (econsult.message.toLowerCase().includes(field.toLowerCase()) && econsult.message.length >= field.length) ||
              field.length == 0
            ) {
              return true;
            } else {
              return false;
            }
          });
      }
    }
  }
}

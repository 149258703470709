import { Injectable } from "@angular/core";
import { Coordinator } from "../classes/flow/session/impl/Coordinator";
import { User } from "../classes/flow/session/impl/User";
import { GraphQLService } from "./graphql.service";

@Injectable({
  providedIn: "root",
})
export class CoordinatorService {
  constructor(
    private readonly graphqlService: GraphQLService,
  ) { }

  public async getCoordinatorByUser(user: User): Promise<Coordinator> {
    const res = await this.graphqlService.query(
      `query {
        coordinators {
          value(where: {userId: {eq: ${user.userId}}}) {
            id
            email
            firstName
            lastName
            userId
            toegangDatumVanaf
            toegangDatumTM
            changes {
              lastChange {
                userId
                time
              }
              fullDetails{
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages {
            message
          }
        }
      }`
    );
  

    return res.data.coordinators.value?.map((coordinator: any) => {
      return new Coordinator({
        userId: coordinator.userId,
        firstName: coordinator.firstName,
        lastName: coordinator.lastName,
        id: coordinator.id,
        userEmail: coordinator.email,
        roles: user.roles,
        accessStartingDate: coordinator.toegangDatumVanaf,
        accessEndDate: coordinator.toegangDatumTM,
        changes: this.graphqlService.createChangesObject(coordinator.changes)
      });
    })?.[0];
  }
}
<ng-container *appModule="'AREA_ACTIONS_MAP_LAYERS'">
  <div class="container mt-5" *ngIf="currentAreaAction">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <h5 class="fw-bold">{{ "COMPONENTS.AREA_ACTIONS.HEADING" | translate }}</h5>
        </div>
        <div class="d-flex w-100 justify-content-around align-items-center mb-3">
          <button
            mat-raised-button
            color="accent"
            (click)="onLeftArrowClick()"
            [disabled]="areaActions.indexOf(currentAreaAction) === 0"
            [attr.aria-label]="'TABLES.GENERAL.PAGINATION.PREVIOUS_PAGE' | translate"
          >
            {{ "TABLES.GENERAL.PAGINATION.PREVIOUS_PAGE" | translate }}
          </button>
          <div class="d-flex flex-column text-center">
            <strong>{{ currentAreaAction.area }} - {{ currentAreaAction.municipality }}</strong>
            <span>{{ currentAreaAction ? currentAreaAction!.zipCodeFrom : "" }} - {{ currentAreaAction ? currentAreaAction!.zipCodeTo : "" }}</span>
            <span class="fst-italic text-muted">{{ currentAreaAction!.dateFrom | date : "dd-MM-YYYY" }} - {{ currentAreaAction!.dateTo | date : "dd-MM-YYYY" }}</span>
          </div>

          <button
            mat-raised-button
            color="accent"
            (click)="onRightArrowClick()"
            [disabled]="areaActions.indexOf(currentAreaAction) === areaActions.length - 1"
            [attr.aria-label]="'TABLES.GENERAL.PAGINATION.NEXT_PAGE' | translate"
          >
            {{ "TABLES.GENERAL.PAGINATION.NEXT_PAGE" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="row">
  <div class="col-md">
    <google-map class="google-map" [center]="center" [zoom]="zoom" [ariaSetSize]="" width="100%" [options]="options">
      <map-marker
        *ngFor="let marker of markerPositions; let i = index"
        [position]="marker.position"
        [icon]="'assets/images/mapIcons/' + marker.state.name.toLowerCase() + '.svg'"
        (mapClick)="openInfo(marker)"
        [options]="marker.options"
      >
      </map-marker>
      <ng-container *appModule="'AREA_ACTIONS'">
        <map-marker
          *ngFor="let marker of areaActionMarkers; let i = index"
          [icon]="'assets/images/mapIcons/areaAction.svg'"
          [position]="marker.position"
          [options]="marker.options"
        >
        </map-marker>
      </ng-container>
      <ng-container *appModule="'AREA_ACTIONS_MAP_LAYERS'">
        <map-polygon *ngFor="let i of mapLayers" [paths]="i"></map-polygon>
      </ng-container>
    </google-map>
    <div class="row">
      <div class="col-12">
        <span class="title py-2">{{ "COMPONENTS.AREA_ACTIONS.LEGEND" | translate }}</span>
        <div class="legenda">
          <ng-container *ngFor="let item of legendItems">
            <div *appIsRole="item.coordinatorOnly ? ['coordinator'] : ['coach', 'coordinator']" class="legenda_item">
              <img [alt]="'COMPONENTS.TILE.STATUSES.' + item.stateName | translate" [src]="'assets/images/mapIcons/' + item.iconFile + '.svg'" />
              <span>{{ "COMPONENTS.TILE.STATUSES." + item.stateName | translate }}</span>
            </div>
          </ng-container>
          <div *appModule="'AREA_ACTIONS'">
            <img [alt]="'MENU.ITEMS.AREA_ACTIONS' | translate" [src]="'assets/images/mapIcons/areaAction.svg'" />
            <span>{{ "MENU.ITEMS.AREA_ACTIONS" | translate }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 position-relative overflow-hidden" *ngIf="selectedRequest">
    <div>
      <div class="closeButton position-absolute end-0 pe-3">
        <button mat-icon-button (click)="closeInfo()">
          <mat-icon class="mb-2">close</mat-icon>
        </button>
      </div>
      <app-energy-consult
      (initRequests)="ngAfterContentInit()"
      [energyConsult]="selectedRequest!"
      [actions]="applicationService.session.user!.status === 'PendingDeleted' ? 3 : 0"
      ></app-energy-consult>
    </div>
  </div>
</div>

<!-- li>.icon+.content>span.title+span.value <--- Emmet snipper for new row -->

<div class="energyConsult">
  <ul class="list-unstyled information">
    <li>
      <div class="icon">
        <mat-icon>checklist_rtl</mat-icon>
      </div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.STATUS" | translate }}</span>
        <span class="value d-flex flex-column gap-1">
          <span class="status">
            {{ "COMPONENTS.TILE.STATUSES." + energyConsult.state.name | translate }}
          </span>
          <app-state-progress-bar
            [flow]="[
              { name: 'PendingDeleted', notion: 'Error' },
              { name: 'Canceled', notion: 'Warning' },
              { name: 'Open' },
              { name: 'New' },
              { name: 'PendingReject', notion: 'Warning' },
              { name: 'Picked' },
              { name: 'Date' },
              { name: 'FilledIn' },
              { name: 'Done' },
              { name: 'Disapproved', notion: 'Warning' }
            ]"
            [currentState]="currentState"
          ></app-state-progress-bar>
        </span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>school</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.SPECIALTY" | translate }}</span>
        <span class="value">{{ energyConsult.specialty ? energyConsult.specialty.name : "" }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>hail</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.COACH" | translate }}</span>
        <span class="value">{{ displayCoachName() ? displayCoachName() : ("COMPONENTS.TILE.ABSENT_DATA" | translate) }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>fact_check</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.DATE_REQUESTED" | translate }}</span>
        <span class="value">{{ energyConsult.requestDate | localDate : "fullDate" }}</span>
      </div>
    </li>

    <li *ngIf="energyConsult.state.name === 'PendingDeleted'">
      <div class="icon"><mat-icon>auto_delete</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.DATE_REQUESTED_DELETION" | translate }}</span>
        <span class="value">{{ energyConsult.lastStateChangeDate! | localDate : "fullDate" }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>work_history</mat-icon></div>
      <div class="content">
        <span class="title">{{ energyConsult.appointmentDate ? ("COMPONENTS.TILE.DATE_PLANNED" | translate) : ("COMPONENTS.TILE.DATE_NOT_PLANNED" | translate) }}</span>
        <span class="value">{{
          energyConsult.appointmentDate ? (energyConsult.appointmentDate | localDate : "EEEE, d MMMM y, HH:mm") : ("COMPONENTS.TILE.ABSENT_DATA" | translate)
        }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>explore</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.LOCATION" | translate }}</span>
        <span class="value">{{ energyConsult.getLocation(applicationService) }}</span>
      </div>
    </li>

    <li>
      <div class="icon"><mat-icon>description</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.EXTRA_MESSAGE" | translate }}</span>
        <span
          class="value"
          [innerText]="energyConsult.message !== 'null' && energyConsult.message.length ? energyConsult.message : ('COMPONENTS.TILE.EMPTY_EXTRA_MESSAGE' | translate)"
        ></span>
      </div>
    </li>

    <!-- <div class="col-8 data">
      <span class="info"> </span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting === true" class="overflow-hidden">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message !== 'null' && this.inspecting !== true" class="text-truncate">{{ energyConsult.message }}</span>
      <span *ngIf="energyConsult.message === 'null' || energyConsult.message === ''" class="text-wrap"></span>
    </div> -->
    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>contact_page</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.CONTACT" | translate }}</span>
        <span class="value">{{ displayResidentName() ? displayResidentName() : "-" }}</span>
      </div>
    </li>

    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>local_phone</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.PHONE_NUMBER" | translate }}</span>
        <span class="value">{{ !energyConsult.resident?.phoneNumber ? "-" : energyConsult.resident?.phoneNumber }}</span>
      </div>
    </li>

    <li
      *ngIf="
        (energyConsult.isActive && energyConsultId) ||
        (includesModule('SHOW_CONTACT_INFO_ON_NEW_REQUESTS') &&
          (applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'))
      "
    >
      <div class="icon"><mat-icon>alternate_email</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.EMAIL" | translate }}</span>
        <span class="value text-break">{{ energyConsult.resident?.email ? energyConsult.resident?.email : "-" }}</span>
      </div>
    </li>

    <li *ngIf="energyConsult.ckdInfo && energyConsult.ckd && energyConsult.ckdInfo.length > 0 && applicationService.session.activeRole.name === 'coordinator'">
      <div class="icon"><mat-icon>move_up</mat-icon></div>
      <div class="content">
        <span class="title">{{ "COMPONENTS.TILE.CKD" | translate }}</span>
        <span class="value text-break">{{ energyConsult.ckdInfo }}</span>
      </div>
    </li>

    <ng-container *appModule="'AREA_ACTIONS'">
      <li *ngIf="applicationService.session.activeRole.name === 'coach' || applicationService.session.activeRole.name === 'coordinator'">
        <div class="icon"><mat-icon>campaign</mat-icon></div>
        <div class="content">
          <span class="title">{{ "COMPONENTS.TILE.AREA_ACTION" | translate }}</span>
          <ng-container *ngIf="energyConsult.areaActions && energyConsult.areaActions.length > 0; else noAreaActions">
            <div *ngFor="let action of energyConsult.areaActions; last as isLast" class="value">
              <div>{{ action.area }}</div>
              <div>{{ action.dateFrom | localDate }} - {{ action.dateTo | localDate }}</div>
              <div>{{ action.zipCodeFrom | postalcode }} - {{ action.zipCodeTo | postalcode }}</div>
              <mat-divider class="my-2" *ngIf="!isLast" />
            </div>
          </ng-container>
          <ng-template #noAreaActions>
            <span class="value">{{ "COMPONENTS.TILE.NO_AREA_ACTION" | translate }}</span>
          </ng-template>
        </div>
      </li>
    </ng-container>
  </ul>

  <div *ngIf="!checkAction(3)" class="actions border-top d-flex flex-wrap">
    <button
      *appIsRole="['coach', 'coordinator']"
      mat-flat-button
      class="regularButton"
      [routerLink]="[applicationService.session.activeRole.name === 'coach' ? '/content/coach/request/' : '/content/coordinator/request/', energyConsult.id]"
    >
      <mat-icon>chevron_right</mat-icon>
      {{ "COMPONENTS.TILE.REQUEST_OPEN" | translate }}
    </button>

    <ng-container *appIsRole="['coach', 'coordinator']">
      <button *ngIf="energyConsult.extraProperties?.note" mat-icon-button class="icon defaultIconButton" (click)="showNoteDialog()">
        <mat-icon [matTooltip]="'COMPONENTS.TILE.CANCEL_REQUEST' | translate">speaker_notes</mat-icon>
      </button>
    </ng-container>

    <button
      *ngIf="energyConsultCanBeCanceled"
      mat-icon-button
      class="discardButton"
      (click)="cancelRequestDialog(energyConsult.id)"
      [attr.aria-label]="'COMPONENTS.DASHBOARD.CANCEL' | translate"
      [matTooltip]="'COMPONENTS.DASHBOARD.CANCEL' | translate"
    >
      <mat-icon [matTooltip]="'COMPONENTS.TILE.CANCEL_REQUEST' | translate">do_not_disturb_on</mat-icon>
    </button>

    <button
      *ngIf="energyConsultCanBeDeleted"
      mat-icon-button
      class="deleteButton"
      (click)="deleteRequestDialog(energyConsult.id)"
      [matTooltip]="'COMPONENTS.DASHBOARD.DELETE' | translate"
    >
      <mat-icon>delete</mat-icon>
    </button>

    <button
      *ngIf="(energyConsult.state.name === 'Done' || energyConsult.state.name === 'FilledIn') && applicationService.session.activeRole.name === 'resident'"
      mat-flat-button
      class="btn-block regularButton"
      [routerLink]="['/content/resident/report/', energyConsult.id]"
    >
      <mat-icon>wysiwyg</mat-icon>
      {{ "COMPONENTS.TILE.SEE_REPORT" | translate }}
    </button>

    <ng-container *appIsRole="['coach', 'coordinator', 'resident']">
      <button
        mat-icon-button
        class="icon defaultIconButton"
        [matTooltip]="'COMPONENTS.DASHBOARD.BOOKMARKS.TOOLTIP' | translate"
        (click)="toggleBookmark()"
        [disabled]="bookmarkService.status !== 'fetched'"
      >
        <mat-icon>{{ energyConsult.bookmarked ? "bookmark" : "bookmark_border" }}</mat-icon>
      </button>
    </ng-container>
  </div>
</div>

<ng-template #deleteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_DELETE.TITLE" | translate }}</h3>
        <p>
          {{ "COMPONENTS.TILE.REQUEST_DELETE.CONFIRM" | translate }}
          {{
            !(!checkAction(2) && energyConsult.canBeDeleted) && applicationService.session.activeRole.name === "resident"
              ? ("COMPONENTS.TILE.REQUEST_DELETE.EMAIL" | translate)
              : ""
          }}
        </p>
        <div class="d-flex gap-3 flex-wrap justify-content-center">
          <button mat-raised-button color="primary" class="m-0" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.CANCEL" | translate }}</button>
          <button mat-raised-button color="warn" class="m-0" (click)="deleteRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #cancelDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.TILE.REQUEST_CANCEL.TITLE" | translate }}</h3>
        <p>{{ "COMPONENTS.TILE.REQUEST_CANCEL.CONFIRM" | translate }}</p>
        <button mat-raised-button color="primary" (click)="dialogService.close()">{{ "COMPONENTS.DIALOG.BACK" | translate }}</button>
        <button mat-raised-button color="warn" (click)="cancelRequest(); dialogService.close()">{{ "COMPONENTS.DIALOG.SUBMIT" | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #noteDialog let-data="data">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md">
        <h3 class="title">{{ "COMPONENTS.COACH_REQUEST.NOTE.ROLE_NOTES" | translate }}</h3>
        <p [innerText]="data.note"></p>
      </div>
    </div>
  </div>
</ng-template>

import { Component, OnDestroy, OnInit, TemplateRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { FilterGroup } from "../../../../components/badge-filters/badge-filters";
import { ApplicationService } from "../../../../services/application.service";
import { EnergyConsultService } from "../../../../services/energy-consult.service";
import { User, UserRoles } from "../../../../classes/flow/session/impl/User";
import { UserService } from "../../../../services/user.service";
import { RetailersService } from "../../../../services/retailers.service";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "../../../../services/dialog.service";
import { MatSelectChange } from "@angular/material/select";
import { Resident } from "../../../../classes/flow/session/impl/Resident";
import { SnackbarService } from "../../../../services/snackbar.service";
import { Role } from "../../../../classes/flow/session/Session";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { UtilitiesService } from "../../../../services/utilities.service";
import { Coach } from "../../../../classes/flow/session/impl/Coach";
import { phoneNumberValidator } from "../../../../validators/phoneNumber";
import { alphaValidator } from "../../../../validators/alpha";
import { Specialty } from "../../../../classes/flow/Questionnaire/Specialty";
import { SpecialtyService } from "../../../../services/specialty.service";
import { houseNumberValidator } from "../../../../validators/houseNumber";
import { postalCodeValidator } from "../../../../validators/postalCode";
import { EnergyConsult } from "../../../../classes/flow/request/EnergyConsult";
import { compareEntities } from "../../../../helpers/compareObjects";
import { Entity } from "../../../../classes/flow/base/Entity";
import { Location } from "@angular/common";
import { Retailer } from "../../../../classes/flow/session/impl/Retailer";
import { Subscription } from "rxjs";
import { ENVIRONMENT } from "../../../../../environments/environment";

@Component({
  selector: "app-profile",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.less"],
})
export class ProfileComponent implements OnInit, OnDestroy {
  public requests: EnergyConsult[] = [];
  public filteredRequests: EnergyConsult[] = [];
  public filters: FilterGroup<EnergyConsult>[] = [];

  public user: User = new User({ email: "" });
  public allRoles = UserRoles;

  public possibleSpecialties: Specialty[] = [];
  public activeSpecialties: { id: number; specialty: Specialty }[] = [];
  public possibleSpecialtiesControl = new FormControl("");

  public viewMode?: Role;

  private usePhoneNumberSubscription?: Subscription;
  public requiredPhonenumber = ENVIRONMENT.MODULES.includes("PHONE_NUMBER_REQUIRED");

  public get shouldIncludeRequests() {
    return (
      this.applicationService.session.activeRole.name === "coach" || (this.applicationService.session.activeRole.name === "coordinator" && this.viewMode?.name === "resident")
    );
  }

  private coachId = "";

  public roles: any = {
    resident: false,
    coach: false,
    coordinator: false,
  };

  public formGroup = new FormGroup({
    firstName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), alphaValidator]),
    lastName: new FormControl<string | null>(null, [Validators.required, Validators.minLength(2), alphaValidator]),
    emailSubscription: new FormControl<boolean | null>(null),
    usePhoneNumber: new FormControl<boolean | null>(null),
    phoneNumber: new FormControl<string | null>({ disabled: true, value: null }, [Validators.required, phoneNumberValidator]),
    postalCode: new FormControl<string | null>(null, [Validators.required, postalCodeValidator]),
    houseNumber: new FormControl<number | null>(null, [Validators.required, houseNumberValidator]),
    houseNumberSuffix: new FormControl<string | null>(null, [alphaValidator, Validators.maxLength(5)]),
  });

  public constructor(
    public applicationService: ApplicationService,
    public userService: UserService,
    public retailersService: RetailersService,
    public route: ActivatedRoute,
    public translateService: TranslateService,
    public requestService: EnergyConsultService,
    public specialtyService: SpecialtyService,
    public dialogService: DialogService,
    public snackService: SnackbarService,
    private utilityService: UtilitiesService,
    private location: Location,
    private router: Router
  ) {}

  // defines the roles
  public initRoles() {
    const roles = this.user.roles.map((role) => role.name);
    this.roles = {
      resident: roles.includes("resident"),
      coach: roles.includes("coach"),
      coordinator: roles.includes("coordinator"),
      retailer: roles.includes("retailer"),
    };
  }

  //Id gets linked to user.
  public async ngOnInit() {
    this.applicationService.setLoading(true);
    const id = this.route.snapshot.paramMap.get("id");
    const user = id ? await this.userService.getUserById(+id) : this.applicationService.session.user;

    if (user) {
      this.user = user;
      this.setFormControls(user);
      this.viewMode = user.userId === this.applicationService.session.user?.userId ? this.applicationService.session.activeRole : user.roles[0];
    } else {
      this.router.navigate(["error/404"]);
    }
    await this.initialize();
    this.applicationService.setLoading(false);
  }

  ngOnDestroy(): void {
    this.usePhoneNumberSubscription?.unsubscribe();
  }

  public pageBack() {
    this.location.back();
  }

  /**
   * Initializes filter per role
   */
  private initFilter() {
    const stateFilters = this.utilityService.initStateFilter(this.requests);
    if (this.shouldIncludeRequests) {
      this.filters = [{ name: "state", filters: stateFilters, activeFilters: [], description: this.translateService.instant("COMPONENTS.DASHBOARD.FILTERS.STATE") }];
      //setLoading(true); Active Filters
      this.filters[0] = this.utilityService.getSavedFiltersFromStorage("state", this.filters[0], "profile");
      this.filterRequests();
    }
  }
  /**
   * Patches the valued of the user in formGroup then checks it with validators. the controls gets enabled if the user is a coordinator
   * @param user The user who called the funtion
   */
  public setFormControls(user: User) {
    const firstname = user.firstName;
    const lastname = user.lastName;

    this.formGroup.reset();
    user instanceof Coach ? this.formGroup.patchValue({ emailSubscription: user.emailSubscription }) : this.formGroup.patchValue({ emailSubscription: false });
    this.formGroup.patchValue({
      firstName: firstname ?? "",
      lastName: lastname ?? "",
    });

    // Phonenumber , Postcalcode , Housenumber  gets checked here with regexe's in different files.
    if (this.user instanceof Resident) {
      this.formGroup.controls.usePhoneNumber.patchValue(!!this.user.phoneNumber ?? false);
      this.formGroup.controls.phoneNumber.patchValue(this.user.phoneNumber ?? null);
      this.formGroup.controls.postalCode.patchValue(this.user.postalCode ?? null);
      this.formGroup.controls.houseNumber.patchValue(this.user.houseNumber ?? null);
      this.formGroup.controls.houseNumberSuffix.patchValue(this.user.houseNumberSuffix ?? null);

      this.formGroup.controls.phoneNumber.enable({ emitEvent: false });
      this.formGroup.controls.postalCode.enable({ emitEvent: false });
      this.formGroup.controls.houseNumber.enable({ emitEvent: false });
      this.formGroup.controls.houseNumberSuffix.enable({ emitEvent: false });

      this.usePhoneNumberSubscription?.unsubscribe();
      this.usePhoneNumberSubscription = this.formGroup.controls.usePhoneNumber.valueChanges.subscribe((value: boolean | null) => {
        if (value) {
          this.formGroup.controls.phoneNumber.enable({ emitEvent: false });
        } else {
          this.formGroup.controls.phoneNumber.disable({ emitEvent: false });
        }
      });
    } else {
      this.formGroup.controls.phoneNumber.disable({ emitEvent: false });
      this.formGroup.controls.postalCode.disable({ emitEvent: false });
      this.formGroup.controls.houseNumber.disable({ emitEvent: false });
      this.formGroup.controls.houseNumberSuffix.disable({ emitEvent: false });
    }
  }

  /**
   * if the user in the aplication is the same as the user it gets the active Role
   */
  public async initialize() {
    if (this.applicationService.session.user?.userId === this.user.userId && this.user.hasRole(this.applicationService.session.activeRole)) {
      await this.userService.getUserByRole(this.applicationService.session.activeRole, this.user);
      this.applicationService.session.user = this.user;
    }

    await this.initRequests();
    this.initFilter();

    if (this.user instanceof Coach) {
      await this.initActiveSpecialties();
      await this.initPossibleSpecialties();
    }
  }

  /**
   * Removes a specialty from the user
   * @param specialty The specialty to remove
   */
  public async removeSpecialty(specialty: { id: number; specialty: Specialty }) {
    if (this.hasActiveSpecialty(specialty.specialty)) {
      await this.specialtyService.removeSpecialtyForCoach(specialty.id);
      await this.initActiveSpecialties();
      await this.initPossibleSpecialties();
    }
  }

  /**
   * Checks whether the specialty is currently active for the user
   * @param specialty The Specialty to check for
   * @returns True if the specialty is currently active for the user, false otherwise
   */
  private hasActiveSpecialty(specialty: Specialty) {
    return this.activeSpecialties.some((activeSpecialty) => activeSpecialty.specialty.id === specialty.id);
  }

  /**
   * Returns the user as a resident whenever he is one
   * @returns The Resident
   */
  public getResident(): Resident | undefined {
    if (this.user instanceof Resident) {
      return this.user as Resident;
    }
  }

  public getRetailer(): Retailer | undefined {
    if (this.user instanceof Retailer) {
      return this.user as Retailer;
    }
  }

  /**
   * Returns the user as a coach whenever he is one
   * @returns The Coach
   */
  public getCoach(): Coach | undefined {
    if (this.user instanceof Coach) {
      return this.user as Coach;
    }
  }

  /**
   * Adds a new specialty to the coach. Checks if it already has it b4 adding
   * @param specialty The specialty to add
   */
  public async addNewSpecialty(specialty: Specialty) {
    if (!this.hasActiveSpecialty(specialty)) {
      await this.specialtyService.addSpecialtyForCoach(this.coachId, specialty.id);
      await this.initActiveSpecialties();
      await this.initPossibleSpecialties();
    }
  }

  /**
   * Get the possible specialties of the application
   */
  private async initPossibleSpecialties() {
    this.possibleSpecialties = await this.specialtyService.getSpecialties();
    this.possibleSpecialties = this.possibleSpecialties.filter((possible) => this.activeSpecialties.every((activeSpecialty) => activeSpecialty.specialty.id !== possible.id));
  }

  /**
   * Get the specialties of the current user
   */
  private async initActiveSpecialties() {
    const res = await this.specialtyService.getSpecialtiesByCoach(this.user.userId!.toString());
    this.coachId = res.coachId;
    this.activeSpecialties = res.specialties;
  }

  public checkUsername(): string {
    return this.user.userId == this.applicationService.session.user?.userId
      ? this.translateService.instant("COMPONENTS.PROFILE.YOUR_DATA")
      : `${this.user.firstName} ${this.user.lastName}`;
  }

  /**
   * Filters the requests
   */
  public filterRequests() {
    this.filteredRequests = this.utilityService.filterRequests(this.filters, this.requests);
    this.utilityService.setSavedFiltersInStorage("state", this.filters[0], "profile");
  }

  public async initRequests() {
    if (this.shouldIncludeRequests) {
      if (this.viewMode?.name === "coach") {
        this.requests = await this.requestService.loadAcceptedByCoach(this.user as Coach);
      } else {
        this.requests = await this.requestService.loadByUser(this.user);
      }
    }
    this.requests = this.requestService.filterRequestsByAllowedSpecialties(this.requests); //filtering requests to only be of allowedspecialties in env file
    this.requests = this.utilityService.filterRequestsByState(this.requests) as EnergyConsult[];
    this.requests = this.utilityService.sortRequestsBy("lastStateChangeDate", true, this.requests) ?? this.requests;
    this.filteredRequests = this.requests;
  }

  public openDialog(dialog: TemplateRef<unknown>) {
    this.dialogService.open({
      template: dialog,
    });
  }

  /**
   * Saves the user info
   */
  public async saveInfo() {
    this.saveUserDetails();
  }

  /**
   * Marks the user as deleted or pending for deletion
   */
  public async deleteUser() {
    await this.userService.deleteUser(this.user);
    this.dialogService?.close();
    this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
    this.applicationService.initMenu();
  }

  /**
   * Reactivates the user's account
   */
  public async reactivateAccount() {
    await this.userService.reactivateAccount(this.user);
    this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
    this.applicationService.initMenu();
  }

  /**
   * Activates the account of the given user
   */
  public async activateAccount() {
    this.userService.activateAccount(this.user);
    this.dialogService?.close();
    this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
  }

  /**
   * Saves the current user details
   */
  private async saveUserDetails() {
    this.user.firstName = this.formGroup.value.firstName ?? undefined;
    this.user.lastName = this.formGroup.value.lastName ?? undefined;

    if (this.user instanceof Coach) {
      this.user.emailSubscription = this.formGroup.value.emailSubscription === true;
    }

    if (this.user instanceof Resident) {
      this.user.phoneNumber = this.formGroup.value.usePhoneNumber ? this.formGroup.value.phoneNumber ?? null : null;
      this.user.postalCode = this.formGroup.value.postalCode ?? undefined;
      this.user.houseNumber = this.formGroup.value.houseNumber ?? undefined;
      this.user.houseNumberSuffix = this.formGroup.value.houseNumberSuffix ?? undefined;
    }

    this.userService.updateUser(this.user);
    this.dialogService?.close();
    this.snackService.open(this.translateService.instant("COMPONENTS.EVERYWHERE.SUCCESS.SAVE_DATA"));
  }

  public saveRole() {
    this.dialogService.close();
    this.snackService.open("Not saved - W.I.P");
  }

  public saveSpecialty() {
    this.dialogService.close();
    this.snackService.open("Not saved - W.I.P");
  }

  public async changeRole(event: MatSelectChange) {
    this.applicationService.setLoading(true);

    if (this.applicationService.session.user?.userId === this.user.userId) {
      await this.applicationService.session.setActiveRole(event.value);
      this.applicationService.session.user = this.user;
      this.applicationService.initMenu();
    }

    this.user = await this.userService.getUserByRole(event.value, this.user);

    if (this.applicationService.session.user?.userId === this.user.userId) this.applicationService.session.user = this.user;

    this.viewMode = event.value;
    try {
      await this.initialize();
    } catch (err) {
      console.error(err);
    }

    this.setFormControls(this.user);

    this.applicationService.setLoading(false);
  }

  /**
   * Gets the house number with the format: 9X
   */
  public get getFormattedHouseNumber() {
    const resident = this.getResident();
    return resident && resident.houseNumber ? "" + resident.houseNumber + (resident.houseNumberSuffix ?? "") : undefined;
  }

  /**
   * Compares two entities
   * @param firstEntity The first entity
   * @param secondEntity The second entity
   * @returns True if the entities are equal, false otherwise
   */
  public compareEntities(firstEntity: Entity, secondEntity: Entity) {
    return compareEntities(firstEntity, secondEntity);
  }
}

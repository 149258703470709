import { AfterContentInit, Component, Input, OnInit, ViewChild } from "@angular/core";
import { GoogleMap, MapInfoWindow, MapMarker } from "@angular/google-maps";
import { RequestState, RequestStates } from "../../../classes/flow/request/RequestStates";
import { Coordinates, EnergyConsult } from "../../../classes/flow/request/EnergyConsult";
import { AreaAction } from "../../../classes/flow/AreaActions/AreaAction";
import { AreaActionService } from "../../../services/area-action.service";
import { ApplicationService } from "../../../services/application.service";

export interface CustomMarker {
  position: google.maps.LatLngLiteral;
  id: string;
  mapMarker?: MapMarker;
  state: RequestState;
  options: {
    zIndex: number;
  };
}

export interface AreaActionCustomMarker {
  position: google.maps.LatLngLiteral;
  id: number;
  options: {
    zIndex: number;
  };
}

@Component({
  selector: "app-consult-map-view",
  templateUrl: "./consult-map-view.component.html",
  styleUrls: ["./consult-map-view.component.less"],
})
export class ConsultMapViewComponent implements OnInit, AfterContentInit {
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild(MapMarker, { static: false }) marker!: MapMarker;

  options: google.maps.MapOptions = {
    fullscreenControl: false,
    streetViewControl: false,
    disableDefaultUI: false,
  };

  @Input("filteredRequests") set filteredRequestsSetter(filteredRequests: EnergyConsult[]) {
    this.filteredRequests = filteredRequests;
    this.setFilteredMarkers();
  }

  public filteredRequests!: EnergyConsult[];

  public markerPositions: CustomMarker[] = [];
  public mapLayers: [google.maps.LatLngLiteral[]] = [[]];
  public areaActionMarkers: AreaActionCustomMarker[] = [];

  public zoom = 9;
  public center: google.maps.LatLngLiteral = { lat: 52.84951333699208, lng: 6.643457242508901 };
  public selectedCustomMarker: CustomMarker | null = null;
  public selectedMarker: CustomMarker | null = null;
  public selectedRequest: EnergyConsult | null = null;

  public areaActions: AreaAction[] = [];
  public currentAreaAction: AreaAction | null = null;

  public legendItems: { stateName: RequestStates; iconFile: string; coordinatorOnly: boolean }[] = [];

  constructor(public readonly applicationService: ApplicationService, private readonly areaActionService: AreaActionService) {}

  ngAfterContentInit(): void {
    return;
  }

  ngOnInit(): void {
    this.setLegendItems();
  }

  loadMap() {
    this.map.googleMap!.setCenter(this.center);
    this.map.googleMap!.setZoom(this.zoom);
  }

  public openInfo(marker: CustomMarker) {
    this.selectedMarker = marker;
    this.selectedRequest = this.filteredRequests.filter((request) => String(request.id) === this.selectedMarker?.id)[0];
  }

  public closeInfo(): void {
    this.selectedRequest = null;
  }

  public onLeftArrowClick() {
    this.setPostalCodeLayers(false);
  }
  public onRightArrowClick() {
    this.setPostalCodeLayers(true);
  }

  //todo create popout for selector of actions
  public onMiddleAreaClick() {
    // todo create selector for all areaActions
  }
  public async getAreaActions() {
    this.applicationService.setLoading(true);
    this.areaActions = await this.areaActionService.all();
    this.areaActions.sort((a, b) => a.dateFrom.getTime() - b.dateFrom.getTime());
    this.applicationService.setLoading(false);
  }

  public setFilteredMarkers() {
    const bottomToTop = [RequestStates.DONE, RequestStates.FILLED_IN, RequestStates.NEW, RequestStates.PICKED, RequestStates.DATE];

    this.markerPositions = this.filteredRequests
      .filter((element) => element.extraProperties?.houseCoordinates || element.extraProperties?.postalcodeCoordinates)
      .map((element) => {
        const coordinates: Coordinates = element.extraProperties?.houseCoordinates
          ? element.extraProperties.houseCoordinates
          : element.extraProperties!.postalcodeCoordinates!;

        return {
          position: {
            lat: Number(coordinates.Latitude),
            lng: Number(coordinates.Longitude),
          },
          id: String(element.id),
          state: element.state,
          options: {
            zIndex: bottomToTop.indexOf(element.state.name),
          },
        };
      });
  }

  public setPostalCodeLayers(next: boolean) {
    if (this.currentAreaAction != null) {
      const currentIndex = this.areaActions.findIndex((obj) => obj.id === this.currentAreaAction!.id);
      const nextAreaAction = this.areaActions[currentIndex + 1];
      const prevAreaAction = this.areaActions[currentIndex - 1];

      if (next && nextAreaAction) {
        this.currentAreaAction = nextAreaAction;
      } else if (prevAreaAction && next == false) {
        this.currentAreaAction = prevAreaAction;
      }

      if (this.mapLayers.length > 0) {
        this.mapLayers = this.currentAreaAction.extraProperties?.coordinates ? [this.currentAreaAction.extraProperties.coordinates] : [[]];
      }
    } else {
      const action = this.areaActions.find((action) => action.extraProperties?.coordinates !== undefined);
      if (action && action.extraProperties?.coordinates) {
        this.currentAreaAction = action;
        this.mapLayers = [action.extraProperties.coordinates];
      } else {
        this.mapLayers = [[]];
      }
    }
  }

  public setAreaActionMarkers() {
    const allAreaActionMarkers: AreaActionCustomMarker[] = [];
    this.areaActions.forEach((element) => {
      if (!element.extraProperties?.AreaCoordinates) return;
      allAreaActionMarkers.push({
        position: {
          lat: Number(element.extraProperties!.AreaCoordinates!.lat),
          lng: Number(element.extraProperties!.AreaCoordinates!.lng),
        },
        id: element.id!,
        options: {
          zIndex: 1,
        },
      });
    });
    this.areaActionMarkers = allAreaActionMarkers;
  }

  private setLegendItems() {
    const order = [
      RequestStates.NEW,
      RequestStates.PICKED,
      RequestStates.DATE,
      RequestStates.FILLED_IN,
      RequestStates.DONE,
      RequestStates.DISAPPROVED,
      RequestStates.CANCELED,
      RequestStates.PENDING_REJECT,
      RequestStates.PENDING_DELETED,
    ];
    const coordinatorOnly = [RequestStates.CANCELED, RequestStates.PENDING_DELETED, RequestStates.DISAPPROVED];
    this.legendItems = Object.keys(RequestStates).map((key) => {
      const state = RequestStates[key as keyof typeof RequestStates];
      return {
        stateName: state,
        iconFile: state.toLowerCase(),
        coordinatorOnly: coordinatorOnly.includes(state),
      };
    });
    this.legendItems.sort((a, b) => order.indexOf(a.stateName) - order.indexOf(b.stateName));
  }
}

import { Injectable } from "@angular/core";
import { GraphQLService } from "./graphql.service";
import { Questionnaire } from "../classes/flow/Questionnaire/Questionnaire";
import { Specialty } from "../classes/flow/Questionnaire/Specialty";
import { Question } from "../classes/flow/Questionnaire/Question";
import { v4 as generateUUID } from "uuid";
import { SnackbarService } from "./snackbar.service";
import { Changes } from "../classes/flow/base/Changes";

export type UpdateKey = "title" | "description" | "questionnaireTypeId" | "active";

@Injectable({
  providedIn: "root",
})
export class QuestionnaireService {
  public constructor(private readonly graphqlService: GraphQLService, private snackService: SnackbarService) {}

  /**
   * Gets all questionnaire that are stored
   * @returns A Promise related to the collection of questionnaires
   */
  public async getQuestionnaires(): Promise<Questionnaire[]> {
    // value(where: {active: {eq: true}}) {
    const res = await this.graphqlService.query(`
    query {
      questionnaires {
        value {
          id
          questionnaireType {
            id
            name
            order
          }
          specialtyQuestionnaires {
             specialty {
               id
               name
               description
               order
             }
          }
          title
          description
          active
          isAnswered
          sortorder
          changes {
            lastChange {
              userId
              time
            }
            fullDetails {
              key
              value {
                userId
                time
              }
            }
          }
        }
        messages{
          message
        }
      }
    }`);

    return res.data.questionnaires.value.map((questionnaire: any) => {
      return {
        id: questionnaire.id,
        title: questionnaire.title,
        description: questionnaire.description,
        questionnaireType: {
          id: questionnaire.questionnaireType.id,
          name: questionnaire.questionnaireType.name,
          order: questionnaire.questionnaireType.order,
        },
        active: questionnaire.active,
        isAnswered: questionnaire.isAnswered ?? false,
        sortOrder: questionnaire.sortorder,
        changes: this.graphqlService.createChangesObject(questionnaire.changes),
        specialties: questionnaire.specialtyQuestionnaires.map((specialty: any) => {
          specialty = specialty.specialty;
          return {
            id: specialty.id,
            name: specialty.name,
            description: specialty.description,
            order: specialty.order,
          };
        }),
      };
    });
  }

  /**
   * Gets a questionnaire by a given id and includes its questions
   * @param questionnaireId The id of the questionnaire to get
   * @returns A promise related to the questionnaire being fetched
   */
  public async getQuestionnaireAndQuestionsById(questionnaireId: number): Promise<Questionnaire> {
    const res = await this.graphqlService.query(
      `query {
        questionnaires {
          value(where: {id: {eq: ${questionnaireId}}}) {
            id
            title
            description
            active
            isAnswered
            sortorder
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
            questionnaireType {
              id
              name
              order
            }
            specialtyQuestionnaires {
               specialty {
                 id
                 name
                 description
                 order
               }
            }
            questions(where: {active: {eq: true}}) {
              id
              name
              questionType {
                id
                name
                prefix
              }
              description
              placeholder
              required
              validation {
                id
                name
              }
              questionOptions {
                id
                value
                changes {
                  lastChange {
                    userId
                    time
                  }
                  fullDetails {
                    key
                    value {
                      userId
                      time
                    }
                  }
                }
              }
              extraProperties
              parentQuestionId
              compareAnswer
              compareOptionId
              operatorType {
                id
                name
                operation
              }
              index
              active
              changes {
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    const questionnaire = res.data.questionnaires.value[0];

    const newQuestionnaire = {
      id: questionnaire.id,
      title: questionnaire.title,
      description: questionnaire.description,
      active: questionnaire.active,
      isAnswered: questionnaire.isAnswered,
      sortOrder: questionnaire.sortOrder,
      changes: this.graphqlService.createChangesObject(questionnaire.changes),
      questionnaireType: {
        id: questionnaire.questionnaireType.id,
        name: questionnaire.questionnaireType.name,
        order: questionnaire.questionnaireType.order,
      },
      specialties: questionnaire.specialtyQuestionnaires.map((specialty: any) => {
        specialty = specialty.specialty;
        return {
          id: specialty.id,
          name: specialty.name,
          description: specialty.description,
          order: specialty.order,
        };
      }),
      questions: questionnaire.questions.map((question: any) => {
        let extraProperties: Question["extraProperties"];
        if (question.extraProperties?.length) {
          extraProperties = JSON.parse(question.extraProperties);
        } else {
          extraProperties = {};
        }

        const newQuestion: Question = {
          id: question.id,
          name: question.name,
          active: question.active,
          description: question.description,
          placeholder: question.placeholder,
          required: question.required,
          index: question.index,
          changes: this.graphqlService.createChangesObject(question.changes),
          questionType: {
            id: question.questionType.id,
            name: question.questionType.name,
            prefix: question.questionType.prefix,
          },
          options: question.questionOptions.map((questionOption: any) => {
            return {
              id: questionOption.id,
              questionReference: generateUUID(),
              value: questionOption.value,
              changes: this.graphqlService.createChangesObject(questionOption.changes),
            };
          }),
          followUpQuestions: [],
          extraProperties: {
            ...extraProperties,
          },
        };

        if (question.validation) {
          newQuestion.validation = {
            id: question.validation?.id,
            name: question.validation?.name,
          };
        }

        if (question.parentQuestionId) {
          newQuestion.logic = {
            compareAnswer: question.compareAnswer,
            parentQuestionId: question.parentQuestionId,
            compareOptionId: question.compareOptionId,
          };

          if (question.operatorType) {
            newQuestion.logic.operatorType = {
              id: question.operatorType?.id,
              name: question.operatorType?.name,
              operation: question.OperatorType?.operation,
            };
          }
        }

        return newQuestion;
      }),
    };

    this.createReferencesBetweenOptions(newQuestionnaire.questions);
    return newQuestionnaire;
  }

  private createReferencesBetweenOptions(questions: Question[]) {
    questions.forEach((currentQuestion) => {
      if (currentQuestion.logic?.parentQuestionId && currentQuestion.logic.compareOptionId) {
        const parent = questions.find((question) => question.id == currentQuestion.logic?.parentQuestionId);
        if (parent) {
          currentQuestion.logic.compareOptionReference = parent.options?.find((option) => option.id == currentQuestion.logic?.compareOptionId)?.questionReference;
        }
      }
    });
  }

  /**
   * Registers the given questions for the questionnaire
   * @param questionnaireId The id of the questionnaire to register the questions for
   * @param questions The questions to add to the questionnaire
   * @param replaceIds An optional collection of old question id's mapped to new id's
   */
  public async saveQuestionsForQuestionnaire(questionnaireId: number, questions: Question[]) {
    const queryBody = questions.map((question) => {
      return this.createQuestionForQuestionnaire(question, questionnaireId);
    });

    const replacedQueryBody = this.graphqlService.hasuraArrayObjectCleaner(queryBody);
    await this.graphqlService.query(`mutation {
      addQuestions(questions: ${replacedQueryBody}) {
        value {
          id
        }
        messages{
          message
        }
      }
    }`);
  }

  /**
   * Creates questions for mutation
   * @param question The question to be formatted
   * @param questionnaireId The id of the questionnaire
   * @returns A formatted question
   */
  private createQuestionForQuestionnaire(question: Question, questionnaireId: number): any {
    return {
      active: true,
      description: question.description,
      index: question.index,
      name: question.name,
      placeholder: question.placeholder,
      questionTypeId: question.questionType.id,
      required: question.required,
      validationId: question.validation?.id,
      compareAnswer: question.logic?.compareAnswer?.trim(),
      compareOptionReference: question.logic?.compareOptionReference,
      compareOptionId: question.logic?.compareOptionId,
      operatorTypeId: question.logic?.operatorType?.id,
      parentQuestionId: question.logic?.parentQuestionId,
      questionnaireId: questionnaireId,
      questionOptions: question.options?.map((option) => {
        return {
          value: option.value,
          questionReference: option.questionReference,
        };
      }),
      extraProperties: JSON.stringify(question.extraProperties) !== "{}" ? JSON.stringify(question.extraProperties) : null,
      childQuestions: question.followUpQuestions.map((followUpQuestion) => this.createQuestionForQuestionnaire(followUpQuestion, questionnaireId)),
    };
  }

  /**
   * Disables all questions specified by the given id's
   * @param ids The id's of the questions to disable
   */
  public async disableQuestionsById(ids: number[]) {
    ids.forEach((id) => this.disableQuestion(id));
  }

  /**
   * Disables a question by id
   * @param id The id of the question to disable
   */
  public async disableQuestion(id: number) {
    this.graphqlService.query(`mutation {
      deleteQuestion(questionId: ${id}) {
        value {
          id
        }
        messages{
          message
        }
      }
    }`);
  }

  /**
   * Saves a questionnaire server-side
   * @param questionnaire The questionnaire to save
   */
  public async createQuestionnaire(questionnaire: Questionnaire) {
    const specialtyIds: { specialtyId: number }[] = questionnaire.specialties.map((specialty) => {
      return { specialtyId: specialty.id };
    });
    await this.graphqlService.query(
      `mutation {
        addQuestionnaire(questionnaire: {
          active: ${questionnaire.active}
          description: "${questionnaire.description}"
          questionnaireTypeId:${questionnaire.questionnaireType.id}
          title: "${questionnaire.title}"
          specialtyQuestionnaires: ${this.graphqlService.hasuraArrayObjectCleaner(specialtyIds)}
        }) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`
    );
  }

  /**
   * Updates a single property of the questionnaire matching the given id
   * @param questionnaire The questionnaire to update
   * @param key The property of the questionnaire to update
   * @param value The new value for the given property
   */
  public async updateProperty(questionnaire: Questionnaire, key: UpdateKey, value: string | boolean | number) {
    value = typeof value === "string" ? `"${value}"` : value;
    const result = await this.graphqlService.query(
      `mutation {
        updateQuestionnaire(input: {
          id: ${questionnaire.id}
          set: {
            ${key}: ${value},
            changes: ${this.graphqlService.formatChangesObject(questionnaire)}
          }
        }) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`
    );

    if (result.data.updateQuestionnaire.messages.length) throw new Error(result.data.updateQuestionnaire.messages[0].message);

    questionnaire.changes = this.graphqlService.createChangesObject(result.data.updateQuestionnaire.value.changes);
  }

  /**
   * Sets the specialties for the questionnaire matching the given id
   * @param questionnaireId The id of the questionnaire to change
   * @param specialties A collection of the new specialties for the questionnaire
   */
  public async updateSpecialties(questionnaireId: number, specialties: Specialty[]) {
    await this.graphqlService.query(`mutation {
      deleteAllSpecialtyQuestionnaireByQuestionnaire(questionnaireId: ${questionnaireId}) {
        value {
          id
        }
        messages{
          message
        }
      }
    }`);

    const content = specialties.map((specialty) => {
      return { specialtyId: specialty.id, questionnaireId: questionnaireId };
    });

    await this.graphqlService.query(`mutation {
        addSpecialtyQuestionnaires(specialtyQuestionnaires: ${this.graphqlService.hasuraArrayObjectCleaner(content)}) {
          value {
            id
          }
          messages{
            message
          }
        }
      }`);
  }

  /**
   * Delete a questionnaire
   * @param questionnaire The questionnaire to delete
   */
  public async deleteQuestionnaire(questionnaire: Questionnaire) {
    await this.graphqlService.query(`
      mutation {
        updateQuestionnaire(input: {
          id: ${questionnaire.id}
          set: {
            active: false
            changes: ${this.graphqlService.formatChangesObject(questionnaire)}
          }
        }) {
          value {
            id
            changes {
              lastChange {
                userId
                time
              }
              fullDetails {
                key
                value {
                  userId
                  time
                }
              }
            }
          }
          messages{
            message
          }
        }
      }`);
  }

  public async getPdfFile(questionnaireId: number): Promise<string> {
    const res = await this.graphqlService.query(`
      query {
        getQuestionnaireAsPDF(questionnaireId: ${questionnaireId}) {
          value {
            content
          }
          messages{
            message
          }
        }
      }`);

    return res.data["getQuestionnaireAsPDF"]?.value?.content;
  }

  public async copyQuestionnaire(questionnaire: Questionnaire) {
    const res = await this.graphqlService.query(`
      mutation {
          copyQuestionnaire(questionnaire: {
            id: ${questionnaire.id},
            title: "${questionnaire.title}",
            description: ${JSON.stringify(questionnaire.description)},
          }) {
            value {
              id
            }
            messages{
              message
            }
          }
        }
    `);

    if (!res.data.copyQuestionnaire.value.id) {
      throw new Error(res.data.copyQuestionnaire.messages[0].message);
    }
  }

  public async setQuestionnaireSortOrder(id: number, sortOrder: number, changes: Changes): Promise<Changes> {
    const res = await this.graphqlService.query(`
      mutation {
          updateQuestionnaire(input: {
            id: ${id},
            set: {
              sortorder: ${sortOrder},
              changes: ${this.graphqlService.hasuraArrayObjectCleaner(changes)}
            }
          }) {
            value {
              id
              changes {
                lastChange {
                  userId
                  time
                }
                fullDetails {
                  key
                  value {
                    userId
                    time
                  }
                }
              }
            }
            messages{
              message
            }
          }
        }
    `);
    if (res.data.updateQuestionnaire.messages.length) throw new Error(res.data.copyQuestionnaire.messages[0].message);
    return res.data.updateQuestionnaire.value.changes;
  }
}

/* eslint-disable indent */
import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Question, Logic } from "../../../../classes/flow/Questionnaire/Question";
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { v4 as generateUUID } from "uuid";
import { DialogService } from "../../../../services/dialog.service";
import { SnackbarService } from "../../../../services/snackbar.service";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs";
import { QuestionnaireService } from "../../../../services/questionnaire.service";
import { Questionnaire } from "../../../../classes/flow/Questionnaire/Questionnaire";
import { Specialty } from "../../../../classes/flow/Questionnaire/Specialty";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { questionAnswerValidator } from "../../../../validators/questionAnswer";
import { questionOperatorValidator } from "../../../../validators/questionOperator";
import { OperatorType } from "../../../../classes/flow/Questionnaire/OperatorType";
import { QuestionType } from "../../../../classes/flow/Questionnaire/QuestionType";
import { Validation } from "../../../../classes/flow/Questionnaire/Validation";
import { QuestionService } from "../../../../services/question.service";
import { Entity } from "../../../../classes/flow/base/Entity";
import { compareEntities } from "../../../../helpers/compareObjects";
import { Option } from "../../../../classes/flow/Questionnaire/Option";
import { ApplicationService } from "../../../../services/application.service";
import { TranslateService } from "@ngx-translate/core";

interface FormGroupInterface {
  id: number;
  formgroup: FormGroup;
  listening: boolean;
}

type ControlGroup = {
  [key: string]: AbstractControl;
};

@Component({
  selector: "app-questionnaire",
  templateUrl: "./questionnaire.component.html",
  styleUrls: ["./questionnaire.component.less"],
})
export class QuestionnaireComponent implements OnInit {
  @ViewChild("deleteDialog")
  public deleteDialog?: TemplateRef<unknown>;

  public questions: Question[] = [];
  public validations: Validation[] = [];
  public operatorTypes: OperatorType[] = [];
  public questionTypes: QuestionType[] = [];

  private flatQuestions: Question[] = [];
  public formGroups: FormGroupInterface[] = [];

  private originalIds: number[] = [];

  private questionnaireId!: number;

  public generalInformation?: FormGroup;

  public panelOpenState = false;

  private questionnaire?: Questionnaire;

  public constructor(
    private readonly application: ApplicationService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly dialog: DialogService,
    private readonly snack: SnackbarService,
    private readonly questionnaireService: QuestionnaireService,
    private readonly questionService: QuestionService,
    private translateService: TranslateService
  ) {}

  //if the route has no id redirect to error/404
  public async ngOnInit() {
    this.application.setLoading(true);
    try {
      if (!this.route.snapshot.paramMap.has("id")) throw new Error("No Questionnaire Id provided.");
      this.questionnaireId = +this.route.snapshot.paramMap.get("id")!;

      await this.initializeQuestionData();
      await this.initQuestions();

      if (this.questionnaire?.isAnswered) throw new Error("Questionnaire can not be edited because it's answered.");
      if (this.questionnaire?.active) throw new Error("Questionnaire can not be edited because it's active");
    } catch (error) {
      this.snack.open(this.translateService.instant("COMPONENTS.ALL_QUESTIONNAIRES.EDIT.ERROR"));
      this.router.navigate(["/content/coordinator/all-questionnaires"]);
    }
    this.application.setLoading(false);
  }

  private async initializeQuestionData() {
    this.operatorTypes = await this.questionService.getAllOperatorTypes();
    this.validations = await this.questionService.getAllValidations();
    this.questionTypes = await this.questionService.getAllQuestionTypes();
  }

  /**
   * Gets and sets all active questions that are in the questionnaire
   */
  private async initQuestions() {
    const questionnaire = await this.questionnaireService.getQuestionnaireAndQuestionsById(this.questionnaireId);
    this.questionnaire = questionnaire;

    await this.setupGeneralInformation(questionnaire);
    questionnaire.questions?.forEach((q) => this.makeFormGroup(q));

    if (questionnaire.questions) {
      // Flatquestions are ALL the questions in an array.
      this.flatQuestions = questionnaire.questions;
      // No logic means a question is a parent question
      const childQuestions = questionnaire.questions.filter((q) => !!q.logic);
      const parentQuestions = questionnaire.questions.filter((q) => !q.logic);

      this.originalIds = questionnaire.questions.map((q) => q.id!);

      childQuestions.forEach((e) => {
        // Een child question kan ook een child van een child zijn, daarom wordt in alle questions gezocht.
        const parentQuestion = questionnaire.questions?.find((q) => e.logic?.parentQuestionId === q.id);
        parentQuestion?.followUpQuestions.push(e);
      });
      this.questions = parentQuestions;
    }

    this.questions.forEach((question) => this.addValidation(question));
    questionnaire.questions?.sort((first, second) => {
      const firstIndex = Number(first.index ?? "0");
      const secondIndex = Number(second.index ?? "0");

      return firstIndex - secondIndex;
    });

    this.sortQuestionsRecursive(this.questions);
  }

  private sortQuestionsRecursive(questions: Question[]) {
    questions.sort((first, second) => {
      const firstIndex = (first.index?.split(".") ?? ["0"]).map((v) => Number(v));
      const secondIndex = (second.index?.split(".") ?? ["0"]).map((v) => Number(v));

      return firstIndex[firstIndex.length - 1] - secondIndex[secondIndex.length - 1];
    });

    for (const question of questions) {
      if (question.followUpQuestions.length) {
        this.sortQuestionsRecursive(question.followUpQuestions);
      }
    }
  }

  /**
   * Uses a Question to make formcontrols and put them in a form group
   * @param q Filled question object (not the Question class)
   */
  private makeFormGroup(q: Question) {
    const controls = this.generateQuestionControls(q.id!, q);
    q.options?.forEach((e, index) => {
      controls["selOption" + e.questionReference] = new FormControl(e.value, index < 2 ? [Validators.required] : []);
    });

    if (q.logic) {
      if (q.logic.compareOptionId) {
        controls["selOption_id"] = new FormControl(q.logic.compareOptionReference);
      }
      if (q.logic.operatorType || q.logic.compareAnswer) {
        controls["operator"] = new FormControl(q.logic.operatorType);
        controls["compare_answer"] = new FormControl(q.logic.compareAnswer);
      }
      controls["parrentQuestionId"] = new FormControl(q.logic.parentQuestionId);
    }

    this.addFormGroup(q.id!, new FormGroup(controls));
  }

  /**
   * Sets the index of the question.
   * @param id
   * @param index
   */
  public setIndex(id: number, index: string) {
    this.getFormGroup(id).controls["index"].setValue(index);
  }

  /**
   * Adds a formgroup to all formgroups.
   * This method will listen for the "questionType" and "validation" FormControls.
   * Also ajusts Question and form if "questionType" is "sel" or not.
   */
  private addFormGroup(id: number, formgroup: FormGroup) {
    this.formGroups.push({ id: id, formgroup: formgroup, listening: false });
    formgroup.controls["questionType"].valueChanges.subscribe((res) => {
      const question = this.getQuestion(id)!;
      this.setFollowUpLogic(res.prefix, question, formgroup);
      this.addValidation(question);
    });
    formgroup.controls["validation"].valueChanges.subscribe(() => {
      const question = this.getQuestion(id)!;
      this.addValidation(question);
    });
  }

  /**
   * Sets follow up logic for the given question
   * @param res The type of question
   * @param question The question to set the new logic for
   * @param formGroup The formGroup of the given question
   */
  private setFollowUpLogic(res: string, question: Question, formGroup: FormGroup) {
    if (res == "sel" || res == "sem") {
      //if question is 'sel' change all followupquestions logic to the sel logic.
      this.setSelLogic(question, formGroup);
    } else {
      //if it is not 'sel' set the followupquestions logic to compare answer and operator.
      this.setCompareLogic(question, formGroup);
      if (res == "dat" || res == "trp") {
        this.disableValidation(formGroup);
      }
    }
  }

  /**
   * Sets sel logic for the given question
   * @param question The question to set the new logic for
   * @param formGroup The formGroup of the given question
   */
  private setSelLogic(question: Question, formGroup: FormGroup) {
    for (const childquestionFGroup of question.followUpQuestions.map((q) => this.getFormGroup(q.id!))) {
      childquestionFGroup.addControl("selOption_id", new FormControl("", Validators.required));
      childquestionFGroup.removeControl("operator");
      childquestionFGroup.removeControl("compare_answer");
    }

    this.disableValidation(formGroup);

    const selectOptionKeys = Object.keys(formGroup.controls).filter((key) => key.startsWith("selOption") && !key.startsWith("selOption_id"));

    if (selectOptionKeys.length === 0) {
      this.addSelectOption(question, formGroup);
      this.addSelectOption(question, formGroup);
    }
  }

  /**
   * Disables the 'validation' control
   * @param formGroup the formgroup that includes the 'validation' control
   */
  private disableValidation(formGroup: FormGroup) {
    formGroup.controls["validation"].setValue("");
    formGroup.controls["validation"].disable();
  }

  /**
   * Sets the logic for the given question to compare answer and operator
   * @param question The question to set the logic for
   * @param formGroup The formGroup of the given question
   */
  private setCompareLogic(question: Question, formGroup: FormGroup) {
    for (const childquestionFGroup of question.followUpQuestions.map((q) => this.getFormGroup(q.id!))) {
      childquestionFGroup.removeControl("selOption_id");
      childquestionFGroup.addControl("operator", new FormControl(""));
      childquestionFGroup.addControl("compare_answer", new FormControl(""));
    }

    question.options?.forEach((e) => {
      formGroup.removeControl(e.id?.toString() ?? "");
      formGroup.removeControl("selOption" + e.questionReference);
    });
    formGroup.controls["validation"].enable();
    question.options = [];
  }

  /**
   * Adds validation for the children of a given question
   * @param question The question which children need the validation
   */
  private addValidation(question: Question, validateInstantly = true) {
    const formGroup = this.getFormGroup(question.id!);
    const formValue = formGroup.getRawValue();
    const parentValidation = formValue["validation"];
    const parentPrefix = formValue["questionType"];

    if (parentPrefix.prefix === "upp") {
      formGroup.controls.unitPrice.enable({ emitEvent: false });
    } else {
      formGroup.controls.unitPrice.disable({ emitEvent: false });
    }

    for (const childquestion of question.followUpQuestions) {
      this.updateValidators(
        [Validators.required, questionAnswerValidator(parentPrefix, parentValidation)],
        this.getFormGroup(childquestion.id!).controls["compare_answer"],
        validateInstantly
      );
      this.updateValidators(
        [Validators.required, questionOperatorValidator(parentPrefix, parentValidation)],
        this.getFormGroup(childquestion.id!).controls["operator"],
        validateInstantly
      );
    }
  }

  /**
   * Sets the validators of a given control
   * @param control The Control of which to update the validators
   * @param validators An optional list of validators to set
   */
  private updateValidators(validators: ValidatorFn[], control?: AbstractControl, validateInstantly = true) {
    if (!control) return;

    if (validators) {
      control.setValidators(validators);
    }
    if (validateInstantly) {
      control.updateValueAndValidity();
      control.markAsTouched();
    }
  }

  /**
   * Adds a select option to the question and FormControl
   * @param question Question class.
   * @param formGroup An optional formgroupInterface to add the control to. If not specified, an existing formgroup will be fetched based on the id of the given question
   */
  public addSelectOption(question: Question, formGroup?: FormGroup) {
    const uuid = generateUUID();
    question.options?.push({ questionReference: uuid, value: "" });
    const group = formGroup ?? this.getFormGroup(question.id!);
    group.addControl("selOption" + uuid, new FormControl("", Validators.required));
  }

  public deleteSelectOption(question: Question, selectOptionId: number, selectOptionReference: string) {
    if (question.options && question.options.length > 2) {
      question.options = question.options.filter((option) => option.questionReference !== selectOptionReference);
      this.getFormGroup(question.id!).removeControl("selOption" + selectOptionReference);
    }
  }

  /**
   * Checks the parent question if 'questionType' === 'dat', returns true or false.
   * @param question
   * @returns
   */
  public checkIfParentIsDate(question: Question): boolean {
    const PQID = question.logic?.parentQuestionId;
    const PQ = this.getFormGroup(this.flatQuestions.find((e) => e.id === PQID)!.id!);
    return PQ.controls["questionType"].value.prefix === "dat";
  }

  /**
   * Gets a Question by question id.
   * @param qID Question id
   * @param questions All questions
   * @returns Question
   */
  public getQuestion(qID: number, questions: Question[] = this.questions): Question | undefined {
    for (const question of questions) {
      if (question.id == qID) {
        return question;
      } else {
        const childQuestion = this.getQuestion(qID, question.followUpQuestions);
        if (childQuestion) {
          return childQuestion;
        }
      }
    }
  }

  /**
   * Gets all select options from parent Question by formgroup.
   * @param formgroup FormGroup
   * @returns All select options from parent Question.
   */
  public getSelOptions(formgroup: FormGroup) {
    return Object.keys(formgroup.controls)
      .filter((e) => e.startsWith("selOption") && e != "selOption_id")
      .map((e) => e.split("selOption")[1]);
  }

  /**
   * Gets all select options from parent Question by formgroup.
   * @param formgroup FormGroup
   * @returns All select options from parent Question.
   */
  public getSelOptionsFormControl(formgroup: FormGroup, id: string): AbstractControl | null {
    return Object.keys(formgroup.controls).find((e) => e === "selOption" + id) ? formgroup.controls["selOption" + id] : null;
  }

  /**
   * Compares two select options
   * @param firstSelectOptionId The first select option
   * @param secondSelectOptionId The second select option
   * @returns True if the options are equal, false otherwise
   */
  public compareSelectOptions(firstSelectOptionId: string, secondSelectOptionId: string): boolean {
    return firstSelectOptionId == secondSelectOptionId;
  }

  /**
   * Gets and returns a FormGroup by id.
   * @param id If the question is new: FormGroup Id. If the question is from the DB: Question id.
   * @returns FormGroup
   */
  public getFormGroup(id: number): FormGroup {
    return this.formGroups.find((e) => e.id === id)!.formgroup;
  }

  /**
   * Creates a new main Question and generates FormGroup with controls.
   */
  public newMainQuestion() {
    const newQuestion = this.makeQuestion();

    this.questions.push(newQuestion);
    this.flatQuestions.push(newQuestion);

    this.addFormGroup(newQuestion.id!, new FormGroup(this.generateQuestionControls(newQuestion.id!)));
  }

  /**
   * Creates a new followUp Question under the parent question and generates FormGroup with controls.
   * @param parentQuestion The question where you want a followUp question
   */
  public newFollowUpQuestion(parentQuestion: Question) {
    const newFollowUpQuestion = this.makeQuestion();
    const controls = this.generateQuestionControls(newFollowUpQuestion.id!);
    controls["parrentQuestionId"] = new FormControl(parentQuestion.id, Validators.required);
    newFollowUpQuestion.logic = this.getFollowUpLogic(controls, parentQuestion.id!);

    this.flatQuestions.push(newFollowUpQuestion);
    parentQuestion.followUpQuestions.push(newFollowUpQuestion);

    this.addFormGroup(newFollowUpQuestion.id!, new FormGroup(controls));
    this.addValidation(parentQuestion, false);
  }

  /**
   * Recursive: Loops over all questions and checks where ID is childQuestion->logic->parentQuestion
   * @param childQuestion The question you want to find the parent for.
   * @param questions First time: all questions.  After: All child questions of question.
   * @returns Question where id == childQuestion->logic->parentQuestion
   */
  public getParentQuestion(childQuestion: Question, questions: Question[] = this.questions): Question | null {
    for (const question of questions) {
      if (question.id == childQuestion.logic?.parentQuestionId) {
        return question;
      } else {
        const parent = this.getParentQuestion(childQuestion, question.followUpQuestions);
        if (parent) {
          return parent;
        }
      }
    }
    return null;
  }

  /**
   * NOT NEEDED?????? (replace if not working: line 19 and 24 in html (data.question_logic.parent_question_id) with this function)
   * @param childQuestion
   * @returns
   */
  public getParentQuestionId(childQuestion: Question): number | undefined {
    return childQuestion.logic?.parentQuestionId;
  }

  public openDeleteDialog(data: Question) {
    this.dialog.open({
      template: this.deleteDialog!,
      data: data,
    });
  }

  /**
   * Finds all children in the entire "sutree" of the given questions
   * @param children The children to find all children of
   * @param confirmedchildren The currently found childrne
   * @returns All children
   */
  private recursiveGetChildren(question: Question, confirmedchildren: Question[]): Question[] {
    confirmedchildren.push(question);
    for (const child of question.followUpQuestions) {
      confirmedchildren = confirmedchildren.concat(this.recursiveGetChildren(child, [...confirmedchildren]));
    }

    return confirmedchildren;
  }

  /**
   * Deletes the given question, including all of its descendants
   * @param question The question to delete
   */
  public deleteQuestionAndChildren(question: Question) {
    const allChildren = this.recursiveGetChildren(question, []);
    this.flatQuestions.forEach((q) => (q.followUpQuestions = []));

    for (const child of allChildren) {
      this.formGroups = this.formGroups.filter((e) => e.id !== child.id);
    }

    // Fetch the children for each parent
    const childQuestions = this.flatQuestions.filter((q) => q.logic && q.id != question.id);
    childQuestions.forEach((e) => {
      const parentQuestion = this.flatQuestions.find((j) => {
        return e.logic?.parentQuestionId === j.id;
      });
      parentQuestion?.followUpQuestions.push(e);
    });

    this.flatQuestions = this.flatQuestions.filter((e) => e.id != question.id);
    this.questions = this.questions.filter((q) => q.id != question.id);
  }

  /**
   * Deletes a question, but keeps all children. The direct children of the deleted question are lifted up by one level, replacing their parent.
   * @param question The question to delete
   */
  public deleteQuestion(question: Question) {
    const parentQuestions = [...this.questions];

    if (!question.logic) {
      //parent question
      this.removeParentButKeepChildren(question, parentQuestions);
    } else {
      //child question
      this.deleteChildrenButKeepChildren(question);
    }
    this.formGroups = this.formGroups.filter((e) => e.id !== question.id);
    question.followUpQuestions = [];

    this.flatQuestions = this.flatQuestions.filter((e) => e.id !== question.id);
    this.questions = parentQuestions;
  }

  /**
   * Deletes a parent question, but keeps its children
   * @param question The parent question to delete
   * @param parentQuestions The parent questions to remove the parent from
   */
  private removeParentButKeepChildren(question: Question, parentQuestions: Question[]) {
    for (const child of question.followUpQuestions) {
      delete child.logic;
      this.getFormGroup(child.id!).removeControl("compare_answer");
      this.getFormGroup(child.id!).removeControl("parrentQuestionId");
      this.getFormGroup(child.id!).removeControl("operator");
      this.getFormGroup(child.id!).removeControl("question_option_option_id");
    }

    // Replace the parent question with his children
    parentQuestions.splice(parentQuestions.indexOf(question), 1, ...question.followUpQuestions);
  }

  /**
   * Deletes a child question, but keeps its children
   * @param question The child question to delete
   */
  private deleteChildrenButKeepChildren(question: Question) {
    for (const child of question.followUpQuestions) {
      const group = this.getFormGroup(child.id!);
      group.controls["parrentQuestionId"].setValue(question.logic?.parentQuestionId);
      if (this.getFormGroup(question.id!).controls["operator"] && group.controls["compare_answer"]) {
        if (group.contains("selOption_id")) group.removeControl("selOption_id");

        group.controls["compare_answer"].setValue(question.logic?.compareAnswer);
        group.controls["operator"].setValue(question.logic?.operatorType);
      } else if (this.getFormGroup(question.id!).controls["selOption_id"]) {
        if (group.contains("operator")) group.removeControl("operator");
        if (group.contains("compare_answer")) group.removeControl("compare_answer");

        if (!group.contains("selOption_id")) {
          group.addControl("selOption_id", new FormControl("", Validators.required));
        }

        group.controls["selOption_id"].setValue("");
      }

      if (child.logic && question.logic) child.logic.parentQuestionId = question.logic.parentQuestionId;
    }

    // Find the parent and replace the current question with its children
    const parentQuestion = this.flatQuestions.find((j) => {
      return question.logic?.parentQuestionId === j.id;
    });
    parentQuestion?.followUpQuestions.splice(parentQuestion.followUpQuestions.indexOf(question), 1, ...question.followUpQuestions);
  }

  /**
   * Saves the questions and sets the old questions to inactive.
   */
  public saveQuestionnaire = async () => {
    this.application.setLoading(true);
    try {
      if (this.formGroups.every((f) => f.formgroup.valid)) {
        await this.questionnaireService.saveQuestionsForQuestionnaire(this.questionnaireId, this.createHierarchy());

        await this.questionnaireService.disableQuestionsById(this.originalIds);
        this.snack.open("Succesvol opgeslagen");
        this.router.navigate(["content/coordinator/all-questionnaires"]);
      } else {
        this.formGroups
          .map((f) => f.formgroup)
          .filter((f) => f.invalid)
          .forEach((f) => f.markAllAsTouched());
        this.snack.open("Het formulier is niet goed ingevuld, het word niet opgeslagen!");
      }
      this.application.setLoading(false);
    } catch (error) {
      this.snack.error();
      this.application.setLoading(false);
    }
  };

  /**
   * Creates a structure between the questions
   * @returns A hierarchical list with questions
   */
  private createHierarchy(): Question[] {
    const questions = this.formGroups.map((formGroup) => this.makeQuestionFromInput(formGroup.formgroup));
    return questions.filter((question) => {
      const parent = questions.find((parent) => {
        return parent.id == question.logic?.parentQuestionId;
      });

      parent?.followUpQuestions.push(question);
      return !question.logic;
    });
  }

  /**
   * Makes a Question object by using the input specified in the given FormGroup
   * @param formGroup The FormGroup to base the question data upon
   * @returns The new question
   */
  private makeQuestionFromInput(formGroup: FormGroup): Question {
    const selOptions: Option[] = Object.keys(formGroup.controls)
      .filter((o) => o.startsWith("selOption") && o !== "selOption_id")
      .map((o) => {
        const suffix = o.split("selOption")[1];
        return {
          id: !isNaN(+suffix) ? +suffix : undefined,
          questionReference: isNaN(+suffix) ? suffix : undefined,
          value: formGroup.getRawValue()[o].trim(),
        };
      });

    const val = formGroup.getRawValue();
    const question: Question = {
      id: val.id,
      index: val.index,
      name: val.name.trim(),
      description: val.description,
      questionType: val.questionType,
      placeholder: val.placeholder.trim(),
      required: val.required,
      active: true,
      options: selOptions,
      followUpQuestions: [],
      extraProperties: {
        unitPrice: val.unitPrice ?? undefined,
      },
    };

    if (val.validation) {
      question.validation = val.validation;
    }

    if (formGroup.contains("parrentQuestionId")) {
      question.logic = {
        parentQuestionId: val.parrentQuestionId,
        compareAnswer: val?.compare_answer == null ? null : val.compare_answer.trim(),
        operatorType: val?.operator,
        compareOptionReference: val?.selOption_id && isNaN(+val.selOption_id) ? val.selOption_id : null,
        compareOptionId: val?.selOption_id && !isNaN(+val.selOption_id) ? +val.selOption_id : undefined,
      };
    }

    return question;
  }

  /**
   * Initializes the name, description and the specialties.
   * @param questionnaire The questionnaire that is used to show the current data to the user
   */
  private async setupGeneralInformation(questionnaire: Questionnaire) {
    this.generalInformation = new FormGroup({
      title: new FormControl(questionnaire.title, Validators.required),
      description: new FormControl(questionnaire.description, Validators.required),
      specialties: new FormControl(questionnaire.specialties, Validators.required),
      questionnaireType: new FormControl(questionnaire.questionnaireType, Validators.required),
    });

    this.registerUpdateHandler(this.generalInformation.controls.title, async (title) => {
      try {
        await this.questionnaireService.updateProperty(questionnaire, "title", title as string);
      } catch (error) {
        this.snack.error();
      }
    });
    this.registerUpdateHandler(this.generalInformation.controls.description, async (description) => {
      try {
        await this.questionnaireService.updateProperty(questionnaire, "description", description as string);
      } catch (error) {
        this.snack.error();
      }
    });
    this.registerUpdateHandler(this.generalInformation.controls.specialties, (specialties) => {
      try {
        this.questionnaireService.updateSpecialties(questionnaire.id!, specialties as Specialty[]);
      } catch (error) {
        this.snack.error();
      }
    });
    this.registerUpdateHandler(this.generalInformation.controls.questionnaireType, (questionnaireType) => {
      try {
        this.questionnaireService.updateProperty(questionnaire, "questionnaireTypeId", questionnaireType.id);
      } catch (error) {
        this.snack.error();
      }
    });
  }

  /**
   * Registers a handler for whenever the given control changes
   * @param controlName The name of the control to subscribe to
   * @param handler A handler to execute whenever the value changes
   * @param timeOut The length of the interval that needs to pass between consecutive changes before a change is considered
   */
  private registerUpdateHandler(control: AbstractControl, handler: (newValue: string | any) => void, timeOut = 500) {
    control.valueChanges
      .pipe(
        debounceTime(timeOut),
        distinctUntilChanged(),
        switchMap(async (newValue) => {
          if (control.valid) {
            try {
              handler(newValue);
            } catch (error) {
              this.snack.error();
            }
          }
        })
      )
      .subscribe();
  }

  /**
   * Gets the follow up logic for a new question
   * @param controls The controls Object containing all controls related to the form of the new question
   * @param parentId The id of the parent of the question
   * @returns The question logic for the new question
   */
  private getFollowUpLogic(controls: ControlGroup, parentId: number): Logic {
    const questionType = this.getFormGroup(parentId).controls["questionType"].value;
    if (questionType.prefix === "sel") {
      controls["selOption_id"] = new FormControl("", Validators.required);
      return {
        parentQuestionId: parentId,
      };
    } else {
      controls["operator"] = new FormControl("", questionType.prefix == "dat" ? [Validators.required] : [Validators.required, Validators.pattern(/^(===$|!=$)/)]);
      controls["compare_answer"] = new FormControl("", Validators.required);
      return {
        compareAnswer: "",
        parentQuestionId: parentId,
      };
    }
  }

  /**
   * Generates standard controls for a new question
   * @param id The id of the new question
   * @param q An optional Question that is used to fill the initial values of the controls
   * @returns An object containing all standard controls for the new question
   */
  private generateQuestionControls(id: number, q?: Question): ControlGroup {
    const hasValidation: boolean = q?.questionType.prefix == "txt" || q?.questionType.prefix == "txa";
    const hasUnitPrice = q?.questionType.prefix === "upp" && q.extraProperties.unitPrice;

    return {
      id: new FormControl(id, Validators.required),
      name: new FormControl(q?.name ?? "", [Validators.required, Validators.pattern(/^[^:]*$/)]),
      questionType: new FormControl(q?.questionType ?? "txt", Validators.required),
      description: new FormControl(q?.description ?? "", Validators.pattern(/^[^:]*$/)),
      placeholder: new FormControl(q?.placeholder ?? "", [Validators.required, Validators.pattern(/^[^:]*$/)]),
      required: new FormControl(q?.required ?? "", Validators.required),
      validation: new FormControl(q ? { value: hasValidation ? q.validation : " ", disabled: !hasValidation } : "", Validators.required),
      unitPrice: new FormControl<number | null>({ value: hasUnitPrice && q.extraProperties.unitPrice ? q.extraProperties.unitPrice : null, disabled: !hasUnitPrice }, [
        Validators.required,
        Validators.min(0),
        Validators.max(1000),
      ]),
      index: new FormControl(""),
    };
  }

  /**
   * Makes a new question
   * @returns A new question
   */
  private makeQuestion(): Question {
    return {
      id: Math.max(0, ...this.flatQuestions.filter((question) => !!question.id).map((question) => question.id!)) + 1,
      name: "",
      active: true,
      description: "",
      index: "",
      placeholder: "",
      required: false,
      questionType: this.questionTypes[0],
      followUpQuestions: [],
      options: [],
      extraProperties: {
        unitPrice: undefined,
      },
    };
  }

  public toQuestions(questions: Question[]): Question[] {
    return questions;
  }

  public reorderQuestion(event: CdkDragDrop<Question[]>) {
    moveItemInArray(event.item.data, event.previousIndex, event.currentIndex);
  }

  /**
   * Compares two entities
   * @param firstEntity The first entity
   * @param secondEntity The second entity
   * @returns True if the entities are equal, false otherwise
   */
  public compareEntities(firstEntity: Entity, secondEntity: Entity) {
    return compareEntities(firstEntity, secondEntity);
  }
}

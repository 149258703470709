import { Host, Injectable, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { RoleName, Session } from "../classes/flow/session/Session";
import { filter, Subject, Subscription } from "rxjs";
import { MenuComponent, MenuItem } from "../components/menu/menu";
import { ENVIRONMENT } from "../../environments/environment";
import Logger from "../classes/Logger";

import { getLanguage } from "../helpers/determineLanguage";
import { StorageService } from "./storage.service";
import { UserService } from "./user.service";
import { NavigationStart, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";

interface Menu {
  menu: {
    description: string;
    items: {
      index: number;
      route: string;
      label: string;
      icon: string;
    }[];
  };
}

@Injectable({
  providedIn: "root",
})
export class ApplicationService implements OnDestroy {
  private static readonly NAME: string = ENVIRONMENT.ORGANISATION;
  private menuMap: Map<RoleName, MenuItem[]> = new Map();

  // No suitable injection parameters
  public session = new Session(this.storageService, this.userService);
  public host?: Host;
  public subscriptions = new Map<string, Subscription[]>();
  public debug = !ENVIRONMENT.PRODUCTION;
  public initialized = false;

  // Menus
  private _menu?: Menu;
  private menuCoordinator?: Menu;
  private menuCoach?: Menu;
  private menuResident?: Menu;
  private menuRetailer?: Menu;
  private menuDeletedUser?: Menu;
  private menuGuest?: Menu;

  // Loading related
  public loading = false;
  private totalLoading = 0;
  public loadingSubject: Subject<boolean> = new Subject();

  public blocked = false;
  public municipalityHomeURL: MenuItem = {
    href: ENVIRONMENT.SOCIALS.LINKS.MAIN,
    route: "",
    label: "MUNICIPALITY",
    icon: "open_in_new",
    index: 0,
  };
  public menu: MenuItem[] = [];
  public menuComponent!: MenuComponent;
  public municipalitySlogan = ENVIRONMENT.SOCIALS.SLOGAN;

  public constructor(
    private readonly storageService: StorageService,
    private readonly userService: UserService,
    private readonly titleService: Title,
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly httpClient: HttpClient
  ) {
    this.setLoading(true);
    this.setMenuMap().then(() => {
      this.setLoading(false);
    });

    this.subscribe(
      "routeChanges",
      this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
        this.blocked = false;
      })
    );
  }

  private async setMenuMap() {
    this.menuCoordinator = await firstValueFrom(this.httpClient.get<Menu>("../../assets/organisation/menu/coordinator.menu.json"));
    // this.menu = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/all.menu.json"));
    this.menuCoach = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/coach.menu.json"));
    this.menuResident = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/resident.menu.json"));
    this.menuRetailer = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/retailer.menu.json"));
    // this.menuDeletedUser = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/deletedUser.menu.json"));
    this.menuGuest = await firstValueFrom(this.httpClient.get<Menu>("../../assets/shared/menu/guest.menu.json"));

    this.menuMap = new Map([
      ["coordinator", this.menuCoordinator.menu.items],
      ["coach", this.menuCoach.menu.items],
      ["resident", this.menuResident.menu.items],
      ["retailer", this.menuRetailer.menu.items],
    ]);

    this.menu = [...this.menuGuest.menu.items, this.municipalityHomeURL];
  }

  /**
   * Starts the initialization of the application
   */
  public async initialize() {
    if (!this.initialized) {
      this.configureLanguage();
      this.finishInitialisation();
    }
  }

  private configureLanguage() {
    const language = getLanguage() ?? "";
    this.titleService.setTitle(ApplicationService.NAME);
    this.translateService.use(language);

    const html = document.getElementsByTagName("html")[0];
    html.setAttribute("lang", language);
  }

  private finishInitialisation() {
    this.host = "Web";
    this.initialized = true;
  }

  /**
   * Updates the title of the application
   * @param title The title
   */
  public updateTitle(title: string) {
    this.titleService.setTitle(`${ApplicationService.NAME} - ${title}`);
  }

  /**
   * Adds a subscription to be collected for auto-unsubscribe
   * @param key subscription key id
   * @param subscription one or multiple Subscriptions
   */
  public subscribe(key: string, ...subscription: Subscription[]) {
    try {
      this.subscriptions.set(key, [...(this.subscriptions.get(key) || []), ...subscription]);
    } catch (error) {
      Logger.error(`Unable to subscribe with key -> ${key}`);
      throw new Error(this.getErrorMessage(error));
    }
  }

  /**
   * Removes all subscriptions depending on key
   * @param key subscription key id
   */
  public unsubscribe(key: string) {
    if (this.debug) console.log(`DEBUG MODE: ${this.debug} [Component] Destroying id -> ${key}`);

    try {
      const subscriptions = this.subscriptions.get(key) || [];
      for (const subscription of subscriptions) subscription.unsubscribe();
    } catch (error) {
      Logger.error(`Unable to unsubscribe with key -> ${key}`);
      throw new Error(this.getErrorMessage(error));
    }
  }

  /**
   * @param error The error given
   * @returns The error as a string in case it is not
   */
  public getErrorMessage(error: unknown) {
    if (error instanceof Error) return error.message;
    return String(error);
  }

  /**
   * Enables the page loader
   */
  public loader() {
    this.loading = false;
  }

  /**
   * Initializes the menu
   */
  public initMenu() {
    if (this.session.user?.isDeleted()) {
      if (!this.menuDeletedUser) return;
      if (!this._menu) return;
      this.menu = this.menuDeletedUser.menu.items.concat(...this._menu.menu.items);
    } else {
      this.menu = this.menuMap.get(this.session.activeRole.name)!.concat(this.municipalityHomeURL);
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe("routeChanges");
  }

  setLoading(load: boolean): void {
    setTimeout(() => {
      if (load) {
        // Increment loading & set loading true
        this.totalLoading++;
        if (this.loading == false) {
          this.loading = true;
        }
      } else {
        // Decrement loading & set loading based on amount of things loading
        this.totalLoading--;
        this.loading = this.totalLoading > 0;
      }
      // Emit to subject
      this.loadingSubject.next(this.loading);
    }, 0);
  }
}
